import React, { useContext, useState,useEffect } from 'react'
import { Form, Button, Toast } from 'react-bootstrap'
import { useParams, Link, navigate } from '@reach/router'
import { AdminContext } from '../../context/AdminContext'
import { ARTICLCE_TYPE } from '../../../constants/constants'
import { updateDraftedArticle } from '../../../api/mutations'
import { API, graphqlOperation } from 'aws-amplify'

export default function ArticleReadyToPublishForm() {
    const admincontext = useContext(AdminContext);
    const { onReview } = admincontext;
    const { id } = useParams();
    const item = onReview.find(i => i.id === id);
    console.log(item);

    function onSubmit(e) {
        e.preventDefault();
        //  navigate("/articles");
    }

    const [paid_free, setPaidOrFree] = useState();
    const [url_id, setURL] = useState();

    const onChangePaidOrFree = (e) => {
        setPaidOrFree(e.target.value);
    }
    const onChangeUrl = (e) => {
        setURL(e.target.value);
    }
    const handleSubmit = async (e) => {
        const param = {
            id: id,
            state: ARTICLCE_TYPE.READYTOPUBLISH,
            paid_free: paid_free,
            url_id: url_id,
        }
        console.log(param);
        try {
            const result = await API.graphql(graphqlOperation(updateDraftedArticle, { input: param }));
            console.log({ result });
            alert("now this article is ready to publish");
        } catch (err) {
            console.log(err);
        }
    }

   
    return (
        <div>
            
            <Form onSubmit={onSubmit} style={{ backgroundColor: "", margin: "10%", borderColor: "grey", paddingLeft: "10%", paddingBottom: "5%", paddingRight: "10%", paddingTop: "5%", borderStyle: "double" }}><br/>
                <h1 style={{ fontFamily: "sans" }}>Publish the article</h1>
                <h3>{item.title}</h3>
                <p>{item.description}</p><br />
                <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Free or Paid</Form.Label>
                    <Form.Control as="select" defaultValue="free" name="paid_free" required onChange={onChangePaidOrFree}>
                        <option value="free">free</option>
                        <option value="paid">paid</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="formBasic">
                    <Form.Label>Article link</Form.Label>
                    <Form.Control name="url_id" type="text" placeholder="Enter article link for this article" required onChange={onChangeUrl} />
                </Form.Group>
                <Form.Group id="formGridCheckbox" style={{ marginTop: "30px" }}>
                    <Form.Check type="checkbox" label="I agree to terms and condtions" required />
                </Form.Group>
                <a href="#terms and conditions">admin terms and condtions</a><br />
                <Link to="/articles">
                    <Button variant="primary" type="cancel">
                        Cancel
                    </Button>
                </Link>
                <Button onClick={handleSubmit} variant="primary" type="submit">
                    Send to publish
                </Button>
            </Form>
        </div>
    )
}
