/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getImageUrl = /* GraphQL */ `
  query GetImageUrl($imgType: String!) {
    getImageUrl(imgType: $imgType) {
      link
      name
      location
    }
  }
`;
export const getDraftedArticle = /* GraphQL */ `
  query GetDraftedArticle($id: ID!) {
    getDraftedArticle(id: $id) {
      id
      title
      thumbnail
      language
      category
      language_category
      description
      content
      resources
      time
      b_type
      paid_free
      writer_id
      url_id
      state
      createdAt
      updatedAt
      owner
      if_list_then_before {
        id
        title
        thumbnail
        language
        category
        language_category
        description
        content
        resources
        created_time
        writer_id
        payment {
          id
          article_id
          content_size
          payment
          writer_id
          createdAt
          updatedAt
          article {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
        }
        url_id
        published_time
        createdAt
        updatedAt
        if_list_then_before {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        if_list_then_next {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        writer {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        creater {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        corrected_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        published_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        views {
          id
          article_id
          counter
          createdAt
          updatedAt
          article {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
        }
      }
      if_list_then_next {
        id
        title
        thumbnail
        language
        category
        language_category
        description
        content
        resources
        created_time
        writer_id
        payment {
          id
          article_id
          content_size
          payment
          writer_id
          createdAt
          updatedAt
          article {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
        }
        url_id
        published_time
        createdAt
        updatedAt
        if_list_then_before {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        if_list_then_next {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        writer {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        creater {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        corrected_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        published_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        views {
          id
          article_id
          counter
          createdAt
          updatedAt
          article {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
        }
      }
      writer {
        id
        email
        user_name
        current_position
        bio
        facebook
        linkedin
        youtube
        tiktok
        url_id
        profile_picture
        owner
        createdAt
        updatedAt
      }
      corrected_by {
        id
        email
        user_name
        current_position
        bio
        facebook
        linkedin
        youtube
        tiktok
        url_id
        profile_picture
        owner
        createdAt
        updatedAt
      }
    }
  }
`;
export const listDraftedArticles = /* GraphQL */ `
  query ListDraftedArticles(
    $filter: ModelDraftedArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDraftedArticles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        thumbnail
        language
        category
        language_category
        description
        content
        resources
        time
        b_type
        paid_free
        writer_id
        url_id
        state
        createdAt
        updatedAt
        owner
        if_list_then_before {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        if_list_then_next {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        writer {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        corrected_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getDeletedArticle = /* GraphQL */ `
  query GetDeletedArticle($id: ID!) {
    getDeletedArticle(id: $id) {
      id
      content_link
      deleted_time
      language
      catecory
      reason
      createdAt
      updatedAt
      deleted_by {
        id
        email
        user_name
        current_position
        bio
        facebook
        linkedin
        youtube
        tiktok
        url_id
        profile_picture
        owner
        createdAt
        updatedAt
      }
    }
  }
`;
export const listDeletedArticles = /* GraphQL */ `
  query ListDeletedArticles(
    $filter: ModelDeletedArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeletedArticles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content_link
        deleted_time
        language
        catecory
        reason
        createdAt
        updatedAt
        deleted_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getArticleReport = /* GraphQL */ `
  query GetArticleReport($id: ID!) {
    getArticleReport(id: $id) {
      id
      created_time
      content
      article_id
      report_type
      createdAt
      updatedAt
      article {
        id
        title
        thumbnail
        language
        category
        language_category
        description
        content
        resources
        created_time
        writer_id
        payment {
          id
          article_id
          content_size
          payment
          writer_id
          createdAt
          updatedAt
          article {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
        }
        url_id
        published_time
        createdAt
        updatedAt
        if_list_then_before {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        if_list_then_next {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        writer {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        creater {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        corrected_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        published_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        views {
          id
          article_id
          counter
          createdAt
          updatedAt
          article {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
        }
      }
      owner
      reporter {
        id
        email
        user_name
        current_position
        bio
        facebook
        linkedin
        youtube
        tiktok
        url_id
        profile_picture
        owner
        createdAt
        updatedAt
      }
    }
  }
`;
export const listArticleReports = /* GraphQL */ `
  query ListArticleReports(
    $filter: ModelArticleReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticleReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        created_time
        content
        article_id
        report_type
        createdAt
        updatedAt
        article {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        owner
        reporter {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getCommentReport = /* GraphQL */ `
  query GetCommentReport($id: ID!) {
    getCommentReport(id: $id) {
      id
      article_id
      created_time
      comment_id
      content
      createdAt
      updatedAt
      article {
        id
        title
        thumbnail
        language
        category
        language_category
        description
        content
        resources
        created_time
        writer_id
        payment {
          id
          article_id
          content_size
          payment
          writer_id
          createdAt
          updatedAt
          article {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
        }
        url_id
        published_time
        createdAt
        updatedAt
        if_list_then_before {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        if_list_then_next {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        writer {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        creater {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        corrected_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        published_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        views {
          id
          article_id
          counter
          createdAt
          updatedAt
          article {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
        }
      }
      comment {
        id
        article_id
        created_time
        report {
          items {
            id
            article_id
            created_time
            comment_id
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        article {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        owner
        user {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
      }
      owner
    }
  }
`;
export const listCommentReports = /* GraphQL */ `
  query ListCommentReports(
    $filter: ModelCommentReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommentReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        article_id
        created_time
        comment_id
        content
        createdAt
        updatedAt
        article {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        comment {
          id
          article_id
          created_time
          report {
            nextToken
          }
          createdAt
          updatedAt
          article {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          owner
          user {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
        }
        owner
      }
      nextToken
    }
  }
`;
export const getTeamMember = /* GraphQL */ `
  query GetTeamMember($id: ID!) {
    getTeamMember(id: $id) {
      id
      full_name
      email
      address
      gender
      phone_number
      bank_acc
      bank_name
      nic
      writer_type
      user_role
      language
      category
      district
      extra
      createdAt
      updatedAt
      userId {
        id
        email
        user_name
        current_position
        bio
        facebook
        linkedin
        youtube
        tiktok
        url_id
        profile_picture
        owner
        createdAt
        updatedAt
      }
    }
  }
`;
export const listTeamMembers = /* GraphQL */ `
  query ListTeamMembers(
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        full_name
        email
        address
        gender
        phone_number
        bank_acc
        bank_name
        nic
        writer_type
        user_role
        language
        category
        district
        extra
        createdAt
        updatedAt
        userId {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getWriterRequest = /* GraphQL */ `
  query GetWriterRequest($id: ID!) {
    getWriterRequest(id: $id) {
      id
      email
      extra
      owner
      createdAt
      updatedAt
      userId {
        id
        email
        user_name
        current_position
        bio
        facebook
        linkedin
        youtube
        tiktok
        url_id
        profile_picture
        owner
        createdAt
        updatedAt
      }
    }
  }
`;
export const listWriterRequests = /* GraphQL */ `
  query ListWriterRequests(
    $filter: ModelWriterRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWriterRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        extra
        owner
        createdAt
        updatedAt
        userId {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      article_id
      content_size
      payment
      writer_id
      createdAt
      updatedAt
      article {
        id
        title
        thumbnail
        language
        category
        language_category
        description
        content
        resources
        created_time
        writer_id
        payment {
          id
          article_id
          content_size
          payment
          writer_id
          createdAt
          updatedAt
          article {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
        }
        url_id
        published_time
        createdAt
        updatedAt
        if_list_then_before {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        if_list_then_next {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        writer {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        creater {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        corrected_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        published_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        views {
          id
          article_id
          counter
          createdAt
          updatedAt
          article {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
        }
      }
      writer {
        id
        email
        user_name
        current_position
        bio
        facebook
        linkedin
        youtube
        tiktok
        url_id
        profile_picture
        owner
        createdAt
        updatedAt
      }
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        article_id
        content_size
        payment
        writer_id
        createdAt
        updatedAt
        article {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        writer {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTeamRatings = /* GraphQL */ `
  query GetTeamRatings($id: ID!) {
    getTeamRatings(id: $id) {
      id
      pointed_to_id
      pointed_by_id
      communication
      article_content
      imoji
      createdAt
      updatedAt
      pointed_to {
        id
        email
        user_name
        current_position
        bio
        facebook
        linkedin
        youtube
        tiktok
        url_id
        profile_picture
        owner
        createdAt
        updatedAt
      }
      pointed_by {
        id
        email
        user_name
        current_position
        bio
        facebook
        linkedin
        youtube
        tiktok
        url_id
        profile_picture
        owner
        createdAt
        updatedAt
      }
      owner
    }
  }
`;
export const listTeamRatingss = /* GraphQL */ `
  query ListTeamRatingss(
    $filter: ModelTeamRatingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamRatingss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        pointed_to_id
        pointed_by_id
        communication
        article_content
        imoji
        createdAt
        updatedAt
        pointed_to {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        pointed_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        owner
      }
      nextToken
    }
  }
`;
export const getRequestedArticles = /* GraphQL */ `
  query GetRequestedArticles($id: ID!) {
    getRequestedArticles(id: $id) {
      id
      catecory
      language
      title
      requested
      createdAt
      updatedAt
      writer {
        id
        email
        user_name
        current_position
        bio
        facebook
        linkedin
        youtube
        tiktok
        url_id
        profile_picture
        owner
        createdAt
        updatedAt
      }
    }
  }
`;
export const listRequestedArticless = /* GraphQL */ `
  query ListRequestedArticless(
    $filter: ModelRequestedArticlesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequestedArticless(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        catecory
        language
        title
        requested
        createdAt
        updatedAt
        writer {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const draftedArticleByWriter = /* GraphQL */ `
  query DraftedArticleByWriter(
    $writer_id: String
    $time: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDraftedArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    draftedArticleByWriter(
      writer_id: $writer_id
      time: $time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        thumbnail
        language
        category
        language_category
        description
        content
        resources
        time
        b_type
        paid_free
        writer_id
        url_id
        state
        createdAt
        updatedAt
        owner
        if_list_then_before {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        if_list_then_next {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        writer {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        corrected_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const draftedArticleByState = /* GraphQL */ `
  query DraftedArticleByState(
    $state: String
    $time: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDraftedArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    draftedArticleByState(
      state: $state
      time: $time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        thumbnail
        language
        category
        language_category
        description
        content
        resources
        time
        b_type
        paid_free
        writer_id
        url_id
        state
        createdAt
        updatedAt
        owner
        if_list_then_before {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        if_list_then_next {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        writer {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        corrected_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const articleReportsByArticleId = /* GraphQL */ `
  query ArticleReportsByArticleId(
    $article_id: String
    $created_time: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticleReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articleReportsByArticleID(
      article_id: $article_id
      created_time: $created_time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        created_time
        content
        article_id
        report_type
        createdAt
        updatedAt
        article {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        owner
        reporter {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const commentReportsByArticle = /* GraphQL */ `
  query CommentReportsByArticle(
    $article_id: String
    $created_time: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentReportsByArticle(
      article_id: $article_id
      created_time: $created_time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        article_id
        created_time
        comment_id
        content
        createdAt
        updatedAt
        article {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        comment {
          id
          article_id
          created_time
          report {
            nextToken
          }
          createdAt
          updatedAt
          article {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          owner
          user {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
        }
        owner
      }
      nextToken
    }
  }
`;
export const paymentByArticle = /* GraphQL */ `
  query PaymentByArticle(
    $article_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    paymentByArticle(
      article_id: $article_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        article_id
        content_size
        payment
        writer_id
        createdAt
        updatedAt
        article {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        writer {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const userRatingsByPointedTo = /* GraphQL */ `
  query UserRatingsByPointedTo(
    $pointed_to_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelTeamRatingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userRatingsByPointedTo(
      pointed_to_id: $pointed_to_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pointed_to_id
        pointed_by_id
        communication
        article_content
        imoji
        createdAt
        updatedAt
        pointed_to {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        pointed_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        owner
      }
      nextToken
    }
  }
`;
export const listPublishedArticles = /* GraphQL */ `
  query ListPublishedArticles(
    $filter: ModelPublishedArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublishedArticles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        thumbnail
        language
        category
        language_category
        description
        content
        resources
        created_time
        writer_id
        payment {
          id
          article_id
          content_size
          payment
          writer_id
          createdAt
          updatedAt
          article {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
        }
        url_id
        published_time
        createdAt
        updatedAt
        if_list_then_before {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        if_list_then_next {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        writer {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        creater {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        corrected_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        published_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        views {
          id
          article_id
          counter
          createdAt
          updatedAt
          article {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;
export const getPublishedArticle = /* GraphQL */ `
  query GetPublishedArticle($id: ID!) {
    getPublishedArticle(id: $id) {
      id
      title
      thumbnail
      language
      category
      language_category
      description
      content
      resources
      created_time
      writer_id
      payment {
        id
        article_id
        content_size
        payment
        writer_id
        createdAt
        updatedAt
        article {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        writer {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
      }
      url_id
      published_time
      createdAt
      updatedAt
      if_list_then_before {
        id
        title
        thumbnail
        language
        category
        language_category
        description
        content
        resources
        created_time
        writer_id
        payment {
          id
          article_id
          content_size
          payment
          writer_id
          createdAt
          updatedAt
          article {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
        }
        url_id
        published_time
        createdAt
        updatedAt
        if_list_then_before {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        if_list_then_next {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        writer {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        creater {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        corrected_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        published_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        views {
          id
          article_id
          counter
          createdAt
          updatedAt
          article {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
        }
      }
      if_list_then_next {
        id
        title
        thumbnail
        language
        category
        language_category
        description
        content
        resources
        created_time
        writer_id
        payment {
          id
          article_id
          content_size
          payment
          writer_id
          createdAt
          updatedAt
          article {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
        }
        url_id
        published_time
        createdAt
        updatedAt
        if_list_then_before {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        if_list_then_next {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        writer {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        creater {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        corrected_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        published_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        views {
          id
          article_id
          counter
          createdAt
          updatedAt
          article {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
        }
      }
      writer {
        id
        email
        user_name
        current_position
        bio
        facebook
        linkedin
        youtube
        tiktok
        url_id
        profile_picture
        owner
        createdAt
        updatedAt
      }
      creater {
        id
        email
        user_name
        current_position
        bio
        facebook
        linkedin
        youtube
        tiktok
        url_id
        profile_picture
        owner
        createdAt
        updatedAt
      }
      corrected_by {
        id
        email
        user_name
        current_position
        bio
        facebook
        linkedin
        youtube
        tiktok
        url_id
        profile_picture
        owner
        createdAt
        updatedAt
      }
      published_by {
        id
        email
        user_name
        current_position
        bio
        facebook
        linkedin
        youtube
        tiktok
        url_id
        profile_picture
        owner
        createdAt
        updatedAt
      }
      views {
        id
        article_id
        counter
        createdAt
        updatedAt
        article {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const articlesByWriter = /* GraphQL */ `
  query ArticlesByWriter(
    $writer_id: String
    $published_time: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPublishedArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    articlesByWriter(
      writer_id: $writer_id
      published_time: $published_time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        thumbnail
        language
        category
        language_category
        description
        content
        resources
        created_time
        writer_id
        payment {
          id
          article_id
          content_size
          payment
          writer_id
          createdAt
          updatedAt
          article {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
        }
        url_id
        published_time
        createdAt
        updatedAt
        if_list_then_before {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        if_list_then_next {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        writer {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        creater {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        corrected_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        published_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        views {
          id
          article_id
          counter
          createdAt
          updatedAt
          article {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;
export const getShare = /* GraphQL */ `
  query GetShare($id: ID!) {
    getShare(id: $id) {
      id
      article_id
      type_and_count
      createdAt
      updatedAt
      article {
        id
        title
        thumbnail
        language
        category
        language_category
        description
        content
        resources
        created_time
        writer_id
        payment {
          id
          article_id
          content_size
          payment
          writer_id
          createdAt
          updatedAt
          article {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
        }
        url_id
        published_time
        createdAt
        updatedAt
        if_list_then_before {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        if_list_then_next {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        writer {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        creater {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        corrected_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        published_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        views {
          id
          article_id
          counter
          createdAt
          updatedAt
          article {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const listShares = /* GraphQL */ `
  query ListShares(
    $filter: ModelShareFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShares(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        article_id
        type_and_count
        createdAt
        updatedAt
        article {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        article_id
        created_time
        report {
          items {
            id
            article_id
            created_time
            comment_id
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        article {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        owner
        user {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      article_id
      created_time
      report {
        items {
          id
          article_id
          created_time
          comment_id
          content
          createdAt
          updatedAt
          article {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          comment {
            id
            article_id
            created_time
            createdAt
            updatedAt
            owner
          }
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      article {
        id
        title
        thumbnail
        language
        category
        language_category
        description
        content
        resources
        created_time
        writer_id
        payment {
          id
          article_id
          content_size
          payment
          writer_id
          createdAt
          updatedAt
          article {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
        }
        url_id
        published_time
        createdAt
        updatedAt
        if_list_then_before {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        if_list_then_next {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        writer {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        creater {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        corrected_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        published_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        views {
          id
          article_id
          counter
          createdAt
          updatedAt
          article {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
        }
      }
      owner
      user {
        id
        email
        user_name
        current_position
        bio
        facebook
        linkedin
        youtube
        tiktok
        url_id
        profile_picture
        owner
        createdAt
        updatedAt
      }
    }
  }
`;
export const commentsByArticle = /* GraphQL */ `
  query CommentsByArticle(
    $article_id: String
    $created_time: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByArticle(
      article_id: $article_id
      created_time: $created_time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        article_id
        created_time
        report {
          items {
            id
            article_id
            created_time
            comment_id
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        article {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        owner
        user {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        user_name
        current_position
        bio
        facebook
        linkedin
        youtube
        tiktok
        url_id
        profile_picture
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      user_name
      current_position
      bio
      facebook
      linkedin
      youtube
      tiktok
      url_id
      profile_picture
      owner
      createdAt
      updatedAt
    }
  }
`;
export const usersByEmail = /* GraphQL */ `
  query UsersByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        user_name
        current_position
        bio
        facebook
        linkedin
        youtube
        tiktok
        url_id
        profile_picture
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listViews = /* GraphQL */ `
  query ListViews(
    $filter: ModelViewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listViews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        article_id
        counter
        createdAt
        updatedAt
        article {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;
export const getView = /* GraphQL */ `
  query GetView($id: ID!) {
    getView(id: $id) {
      id
      article_id
      counter
      createdAt
      updatedAt
      article {
        id
        title
        thumbnail
        language
        category
        language_category
        description
        content
        resources
        created_time
        writer_id
        payment {
          id
          article_id
          content_size
          payment
          writer_id
          createdAt
          updatedAt
          article {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
        }
        url_id
        published_time
        createdAt
        updatedAt
        if_list_then_before {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        if_list_then_next {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
        writer {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        creater {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        corrected_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        published_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        views {
          id
          article_id
          counter
          createdAt
          updatedAt
          article {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const viewsByArticle = /* GraphQL */ `
  query ViewsByArticle(
    $article_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelViewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    viewsByArticle(
      article_id: $article_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        article_id
        counter
        createdAt
        updatedAt
        article {
          id
          title
          thumbnail
          language
          category
          language_category
          description
          content
          resources
          created_time
          writer_id
          payment {
            id
            article_id
            content_size
            payment
            writer_id
            createdAt
            updatedAt
          }
          url_id
          published_time
          createdAt
          updatedAt
          if_list_then_before {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          if_list_then_next {
            id
            title
            thumbnail
            language
            category
            language_category
            description
            content
            resources
            created_time
            writer_id
            url_id
            published_time
            createdAt
            updatedAt
          }
          writer {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          creater {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          corrected_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          published_by {
            id
            email
            user_name
            current_position
            bio
            facebook
            linkedin
            youtube
            tiktok
            url_id
            profile_picture
            owner
            createdAt
            updatedAt
          }
          views {
            id
            article_id
            counter
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;
export const getReaction = /* GraphQL */ `
  query GetReaction($id: ID!) {
    getReaction(id: $id) {
      id
      time
      article_id
      reader
      reaction
      createdAt
      updatedAt
    }
  }
`;
export const listReactions = /* GraphQL */ `
  query ListReactions(
    $filter: ModelReactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        time
        article_id
        reader
        reaction
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const reactionByArticle = /* GraphQL */ `
  query ReactionByArticle(
    $article_id: String
    $time: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reactionByArticle(
      article_id: $article_id
      time: $time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        time
        article_id
        reader
        reaction
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHomePageContent = /* GraphQL */ `
  query GetHomePageContent($id: ID!) {
    getHomePageContent(id: $id) {
      id
      data
      createdAt
      updatedAt
    }
  }
`;
export const listHomePageContents = /* GraphQL */ `
  query ListHomePageContents(
    $filter: ModelHomePageContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHomePageContents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const lihinistatistics = /* GraphQL */ `
  query Lihinistatistics($items: AWSJSON!) {
    lihinistatistics(items: $items) {
      id
      article_id
      view_count
    }
  }
`;
