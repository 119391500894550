import React, { useContext, useState } from 'react'
import { Form, Button, Alert } from 'react-bootstrap'
import { navigate, useParams } from '@reach/router'
import { AdminContext } from './../../context/AdminContext'
import { API, graphqlOperation } from 'aws-amplify'
import { publishArticleAndView } from '../../../api/mutations'

export default function ArticlePublishForm() {
    const { id } = useParams();
    const admincontext = useContext(AdminContext);
    const { readyToPublish } = admincontext;
    const item = readyToPublish.find(i => i.id === id);

    //    Paragraph text count
    var json = JSON.parse(item.content);

    var words = "";
    var paraText = json.elements.map((i) => {
        if (i.type === "para") {
            words = words + i.content;
            return words;
        }
    });

    function countWords(text) {
        text.toString().replace(/(^\s*)|(\s*$)/gi, "");
        text.toString().replace(/[ ]{2,}/gi, " ");
        text.toString().replace(/\n /, "\n");
        return text.toString().split(' ').length;
    }

    const onSubmit = (e) => {
        e.preventDefault();
        navigate("/articles");
    }
    const [payment, setPayment] = useState(0);
    const onChangePayment = (e) => { setPayment(e.target.value) };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const param = {
            draftedArticleId: item.id,
            payment: parseInt(payment),
            contentSize: countWords(words),
        }
        console.log(param);
        try {
            await API.graphql(graphqlOperation(publishArticleAndView,param));
            //const result = await API.graphql(graphqlOperation(publishArticleAndView, {input: param}));
            //console.log(result);
            alert("now this aerticle is about to publish");
        } catch (err) {
            console.log(err);
        }

    }

    const handleCancel = () => {
        navigate("/articles");
    }


    return (
        <>
            <Form onSubmit={onSubmit}>
                <Form.Group controlId="formBasic">
                    <Form.Label>Content size: {countWords(words)}</Form.Label>
                </Form.Group>
                <Form.Group controlId="formBasic">
                    <Form.Label>Payment</Form.Label>
                    <Form.Control
                        name="payment"
                        type="number"
                        inputMode="numeric"
                        placeholder="Enter Payment"
                        required
                        onChange={onChangePayment} />
                </Form.Group>

                <Button variant="primary" type="cancel" onClick={handleCancel}>
                    Cancel
                 </Button>

                <Button variant="primary" type="submit" onClick={handleSubmit}>
                    Submit
                 </Button>
            </Form>
        </>
    )
}
