import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from '@reach/router';

class WriterNavBar extends Component {
    render() {
        return (
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Navbar.Brand style={{color: '#ffca18'}}>
                    <Link style={{color: '#ffca18'}} to='/'> Home </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                    <Nav.Link style={{color: '#ffca18'}}>
                        <Link style={{color: '#ffca18'}} to='/signin'>
                            SIGN IN
                        </Link>
                    </Nav.Link>
                    <Nav.Link href="#signup" style={{color: '#ffca18'}}>
                        <Link style={{color: '#ffca18'}} to='/signin'>
                            SIGN UP
                        </Link>
                    </Nav.Link>
                    </Nav>
                    <Nav>
                    <Nav.Link href="#deets" style={{color: '#ffca18'}}>Profile</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default WriterNavBar;