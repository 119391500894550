import React  from 'react';
import { Image, Spinner, Row, Col, InputGroup, FormControl, Accordion, Card, Button } from 'react-bootstrap';
import { Link } from '@reach/router';
import 'image-upload-react/dist/index.css'
import axios from 'axios';
import { API, graphqlOperation } from "aws-amplify";
import { getImageUrl } from '../../../graphql/queries';
 
//----------------------- lihini custom image upload ----------------------
// this custom component can upload image to backend 
// if user choose image file it immediatly call backend 
// and get image upload url and then upload images to
// the back end server. the component hold the url of
// uploaded image to return to parent component using 
// notify prop create notify as a function to update
// parent component
class UploadContentImage extends React.Component {

  constructor(props) {
    super(props);
    //console.log(props)
    this.state = {
      website: props.data.website ? props.data.website : '',
      caption: props.data.caption ? props.data.caption : '',
      licenseurl: props.data.licenseurl ? props.data.licenseurl: '',
      licensename: props.data.licensename ? props.data.licensename: '',
      author: props.data.author ? props.data.author: '',
      file: (this.props.file) ? this.props.file : null,
      uploading: false,
    }
    this.onImageSelected = this.onImageSelected.bind(this);
    this.onImageSelected = this.onImageSelected.bind(this);
    this.updateState = this.updateState.bind(this);
  }
  
  async onImageSelected(event) {
    const { notify, indexId } = this.props;
    notify(null, indexId);
    // read file if exist
    if(event.target.files && event.target.files[0]) {
      // throw if size > 512kb
      if(event.target.files[0].size < 1024*1024/2) {
        // throw if file is not in format
        let gqlType = 'png';
        if(event.target.files[0].type === 'image/png') {
          gqlType = 'png'
        } else if(event.target.files[0].type === 'image/jpg') {
          gqlType = 'jpg'
        } else if(event.target.files[0].type === 'image/jpeg') {
          gqlType = 'jpeg'
        } else {
          return;
        }
        this.setState({
          file: URL.createObjectURL(event.target.files[0]),
          uploading: true
        })
        
        const urlData = await API.graphql(
          graphqlOperation(
            getImageUrl, 
            {imgType: gqlType}
          )
        );
        const axiosResponse = await axios.put(urlData.data.getImageUrl.link, 
          event.target.files[0], 
          {
            headers: {
              'Content-Type': event.target.files[0].type
            }
          }
        );
        if(axiosResponse.status === 200) {
          // uploading success
          // notify to create article form
          notify(urlData.data.getImageUrl.location, indexId)
        } else {
          // uploading faild
        }
        this.setState({
          file: URL.createObjectURL(event.target.files[0]),
          uploading: false,
        })
      } else {
        this.setState({
          file: null,
          uploading: false,
        })
        // show error
      }
    } else {
      this.setState({
        file: null,
        uploading: false,
      })
      // show error
    }
  }

  componentDidMount() {
    //console.log(this.props);
    if(this.props.value) {
      //console.log('updaing file', this.props.value)
      this.setState({
        file: this.props.value,
        uploading: false,
      })
    }
  }

  updateState(value, itemName) {
    const { captionChange, indexId } = this.props;
    captionChange(value, indexId, itemName);
  }

  render() {
    //console.log(this.state);
    const { captionChange, indexId } = this.props;
    return (
      <div>
        <p>Maximum file size is 512kb. Use  <Link to="/article/guide">best practices</Link>. Ask more from admin</p>
        <input type="file" onChange={this.onImageSelected}/>
        <br/>
        {(this.state.uploading) ?
          <Spinner animation="border" variant="warning" /> :
          ''
        }
        <br/>
        <Image src={this.state.file} fluid/>
        <br/>
        <br/>
        <Accordion>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                Caption Details
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <InputGroup size="sm" className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroup-sizing-sm">Caption</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl value={this.props.data.caption} aria-label="Small" aria-describedby="inputGroup-sizing-sm" onChange={(e) => {this.updateState(e.target.value, 'caption')}}/>
                </InputGroup>

                <InputGroup size="sm" className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroup-sizing-sm">Author</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl value={this.props.data.author} aria-label="Small" aria-describedby="inputGroup-sizing-sm" onChange={(e) => {this.updateState(e.target.value, 'author')}}/>
                </InputGroup>

                <InputGroup size="sm" className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroup-sizing-sm">Website URL</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl value={this.props.data.website} aria-label="Small" aria-describedby="inputGroup-sizing-sm" onChange={(e) => {this.updateState(e.target.value, 'websiteurl')}}/>
                </InputGroup>

                <InputGroup size="sm" className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroup-sizing-sm">License Name</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl value={this.props.data.licensename} aria-label="Small" aria-describedby="inputGroup-sizing-sm" onChange={(e) => {this.updateState(e.target.value, 'licensename')}}/>
                </InputGroup>

                <InputGroup size="sm" className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroup-sizing-sm">License URL</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl value={this.props.data.licenseurl} aria-label="Small" aria-describedby="inputGroup-sizing-sm" onChange={(e) => {this.updateState(e.target.value, 'licenseurl')}}/>
                </InputGroup>

              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        
      </div>
    );
  }
}

export default UploadContentImage;