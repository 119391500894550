import React, { Component } from 'react';
import { Card, Button } from 'react-bootstrap';
import {  Pencil, BookmarkStarFill, BookmarkDashFill, BookmarkCheckFill, CheckSquareFill, BarChart } from 'react-bootstrap-icons'
import { Slide } from '@material-ui/core';
import { ARTICLCE_TYPE } from '../../constants/constants';
import { Redirect } from '@reach/router'
import './style.css';

function getIcon ( type , count , fn) {
    if(count) {
        count = count.toSting()
    } else {
        count = 0
    }
    if (ARTICLCE_TYPE.DRAFTED === type) {
        return (
            <Button variant="light" onClick={fn}>
                <Pencil/>
            </Button>
        );
    } else if (ARTICLCE_TYPE.PUBLISHED === type) {
        return (
            <div>
                <Button variant="light">
                    <CheckSquareFill style={{marginRight: 5}}/>
                </Button>
                <Button variant="light">
                    <BarChart/>
                    {`- ${count}`}
                </Button>
            </div>
            )
    } else if (ARTICLCE_TYPE.READYTOPUBLISH === type) {
        return (<Button variant="light"><BookmarkCheckFill/></Button>);
    } else if (ARTICLCE_TYPE.RJECTED === type ) {
        return (<Button variant="light"><BookmarkDashFill/></Button>);
    } else  if (ARTICLCE_TYPE.ONREVIEW === type ) { // on review
        return (<Button variant="light"><BookmarkStarFill/></Button>);
    } else {
        return (<Button variant="light"><BookmarkStarFill/></Button>);
    }
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

class ArticleCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            itsTime: false,
            edit: false,
        }
        this.onClickEdit = this.onClickEdit.bind(this);
    }
    
    async componentDidMount() {
        await sleep(1500 + Math.random());
        this.setState({
            itsTime: true
        })
    }

    onClickEdit() {
        this.setState({
            edit: true,
        })
    }

    render() {
        const { data } = this.props
        const icon = getIcon(this.props.type, this.props.count? this.props.count: null, this.onClickEdit);
        if(this.state.edit) { return (<Redirect state={{ id: data.id }} from="/" to="/edit/article/content" noThrow/>)}
        return (
            <div style={{marginTop: 10, marginBottom: 10}}>
                <Slide direction="left" in={this.state.itsTime} mountOnEnter unmountOnExit>
                    <Card className="cardstyle">
                        <Card.Img variant="top" src={data.thumbnail} />
                        <Card.Body>
                        <Card.Text>
                            {data.title}
                        </Card.Text>
                        <Card.Text>
                            {icon}
                        </Card.Text>
                        </Card.Body>
                    </Card>
                </Slide>
            </div>
        );
    }
}

export default ArticleCard;