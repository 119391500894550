import React from 'react';
import  {Button, Form, Col, Row} from 'react-bootstrap'
import UploadImage from './../../common/UploadImage';
import { Redirect } from '@reach/router'
import { API, graphqlOperation } from "aws-amplify";
import { updateDraftedArticle } from '../../../api/mutations';
import { getDraftedArticle } from '../../../api/queries';
import { Auth } from 'aws-amplify';
import { ARTICLE_CAT} from './../../../constants/category'
import { ARTICLCE_TYPE } from './../../../constants/constants';


class EditTitleStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            thumbnail: null,
            articleId: null,
            goToContent: false
        }
        this.setThumbnailUrl = this.setThumbnailUrl.bind(this);
        this.updateValues = this.updateValues.bind(this);
        this.setValues = this.setValues.bind(this);
        this.setGoToConten = this.setGoToConten.bind(this);
    }

    setThumbnailUrl(urlString) {
        console.log(urlString);
        this.setState({
            thumbnail: urlString
        })
    }

    async componentDidMount() {
        let { id } = this.props.location.state;
        let param = {
            id,
        }
        console.log(param)
        try {
            const result = await API.graphql(graphqlOperation(getDraftedArticle, param));
            this.setValues(result.data.getDraftedArticle);
            console.log(result);
        } catch (err) {
            console.log(err)
        }
    }

    setValues(values) {
        this.setState({
            id: values.id,
            thumbnail: values.thumbnail,
            language: values.language,
            category: values.category,
            language_category: values.language_category,
            title: values.title,
            description: values.description,
            b_type: values.b_type,
        })
    }

    async updateValues(e) {
        console.log('click')
        e.preventDefault();
        let form = e.target;
        console.log('click')
        if(!form.elements.title.value || !form.elements.description.value || !form.elements.b_type.value) {
            console.log('click')
            return
        } else if (!form.elements.category.value || !form.elements.language.value || !this.state.thumbnail) {
            console.log('click')
            return
        }

        var param = {
            id: this.state.id,
            thumbnail: this.state.thumbnail,
            language: form.elements.language.value,
            category: form.elements.category.value,
            language_category: `${form.elements.language.value}#${form.elements.category.value}`,
            title: form.elements.title.value,
            description: form.elements.description.value,
            b_type: form.elements.b_type.value,
        }
        console.log(param)
        const result = await API.graphql(graphqlOperation(updateDraftedArticle, {input: param}));
        this.setGoToConten(true)
    }

    setGoToConten(b) {
        this.setState({
            goToContent: b
        });
    }

    render() {
        console.log(this.state);
        if(this.state.goToContent) return <Redirect state={{ id: this.state.id }} from="/" to="/create/article/content" noThrow/>;
        return (
            <div style={{marginLeft: 10}}>
                <Row>
                    <Col md={{ span: 5, offset: 4 }}>
                        <Form onSubmit={this.updateValues}>
                            <Form.Group controlId="exampleForm.ControlInput1">
                                <Form.Label>Title</Form.Label>
                                <Form.Control name="title"  ref="title" placeholder={this.state.title} />
                            </Form.Group>
                            <br/>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>Short description</Form.Label>
                                <Form.Control name="description" placeholder={this.state.description} />
                            </Form.Group>
                            <br/>
                            <p> Title Image </p>
                            <UploadImage file={this.state.thumbnail} notify={this.setThumbnailUrl} />
                            <br/>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>Language</Form.Label>
                                <Form.Control name="language" as="select">
                                <option key={'English'}>English</option>
                                <option key={'සිංහල'}>සිංහල</option>
                                <option>தமிழ்</option>
                                </Form.Control>
                                <p style={{color: "#999999"}}> old value : {this.state.language}</p>
                            </Form.Group>
                            <br/>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>Category</Form.Label>
                                <Form.Control name="category" as="select" >
                                {
                                    ARTICLE_CAT.map(obj => (
                                        <option 
                                          value={obj}
                                          label={obj}
                                        />
                                      ))
                                }
                                </Form.Control>
                                <p style={{color: "#999999"}}> old value : {this.state.category}</p>
                            </Form.Group>
                            <br/>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>B-type</Form.Label>
                                <Form.Control name="b_type" as="select" value={this.state.b_type}>
                                    <option>List</option>
                                    <option>Sponcerd</option>
                                    <option>Single</option>
                                </Form.Control>
                                <p style={{color: "#999999"}}> old value : {this.state.b_type}</p>
                            </Form.Group>
                            <br/>
                            <Button variant="warning" type="submit">
                                Update
                            </Button>
                        </Form>
                        <div>
                            <br></br>
                            <br></br>
                            <br></br>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}


export default EditTitleStep;