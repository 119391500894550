export const ARTICLE_GUIDE = [
    {
        id: 1,
        title: "Create Article",
        content: {
            "Title": {
                "The title should be concise, omitting terms that are implicit.":"",
                "The title must be eye-catching, clear, and interesting.":"",
                "Abbreviations should be avoided within the title.":"",
                "The running title is about 10 words in length and not to exceed 50 characters.":"",
            },
            "Short Description": {
                "The short description of the article must be catchy and clearly understandable.":"",
                "The running word count in short description is100 to 150.":"",
            },
            "Title Image": {
                "The title image is like a thumbnail image.":"",
                "Use a high quality image under the size 100KB.":"",
            },
            "Language": {
                "Writers can take any language style.":"",
                "The title and short description should write under the selected language.":"",
            },
            "Category": {
                "Your article should categories the most suitable category as following the Lihini.Medias' category list.":"",
            },
            "B-Type": {
                "List, Sponsored and Single is the three types under the B type.":"",
                "If your article has a continuation you can put it under the List type.(Ex: Season wise, Chapter wise)":"",
                "If someone sponsored your article and should be under the Sponced type.":"",
                "Single and not sponsored articles can be put under the Single type.":"",
            },
        },

    },
    {
        id: 2,
        title: "Content of Article",
        content: {
            "": {
                "Content of the article can add high quality images, subtitles,and paragraphs as you wish.":"",
                "The article content has to get attention, has to be interesting, has to be easy to read.":"",
                "The reader must be identified.":"",
                "Find the main goal of writing an article.":"", 
                "The goal can be anything from providing information, entertainment, and advice or for comparing, etc.":"",
            },
        },
    },
]

export const ARTICLE_GUIDE_MAIN_DESCR = "Lihini.Media is mainly defen on the articles quality.As an example Vijaya, Mihira was most popular among the children in the last 15 years ago, because their articles quality is good at that time. But now their popularity is low. That’s why our main goal is to provide high quality articles.So we introduce the Article Guide to you. Because we need good qualified and creative articles with best practices."
