import React, { Component } from 'react';
import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';
import { API, graphqlOperation, Auth } from "aws-amplify";
import { updateHomePageContent } from '../../api/mutations';
import { getHomePageContent, getPublishedArticle } from '../../api/queries';

class HomePageSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            updating: false,
            elements: [],
        }
        this.addPost = this.addPost.bind(this);
        this.setUpdateing = this.setUpdateing.bind(this);
        this.deletePost = this.deletePost.bind(this);
    }
    

    async componentDidMount() {
        var param = {
            id : 'english'
        }
        const result = await API.graphql(graphqlOperation(getHomePageContent, param))
        console.log(JSON.parse(result.data.getHomePageContent.data))
        this.setState({
            elements: JSON.parse(result.data.getHomePageContent.data)
        })
    }

    async setUpdateing(b) {
        this.setState({
            updating: b
        })
    }

    async addPost(e) {
        e.preventDefault();
        this.setUpdateing(true);
        const form = e.currentTarget.elements;
        if (!form.category.value || !form.id.value) {
            return
        }

        // read item

        var param = {
            id : form.id.value
        }
        // a858f5a9-04a1-468f-a9cf-2c11997361e4
        const articleResult = await API.graphql(graphqlOperation(getPublishedArticle, param))
        console.log(articleResult)
        
        var article = articleResult.data.getPublishedArticle;
        console.log(article);

        console.log(this.state.elements);
        var c = this.state.elements;
        article.cat = form.category.value
        c.push(article);

        console.log(c);


        var param = {
            id : 'english',
            data: JSON.stringify(c),
        }
        const result = await API.graphql({query: updateHomePageContent, variables: {input: param}})
        console.log(result);

        this.setUpdateing(false);
    }

    async deletePost(id) {
        console.log(id)
        var c = this.state.elements;
        c.forEach((item, index) => {
            if(item.id === id) {
                c.splice(index, 1);
            }
        });
        
        var param = {
            id : 'english',
            data: JSON.stringify(c),
        }
        const result = await API.graphql({query: updateHomePageContent, variables: {input: param}})
        console.log(result);
        this.setState({
            elements: JSON.parse(result.data.updateHomePageContent.data)
        })

    }


    render() {
        const { elements } = this.state;
        if(!elements) elements = []
        return (
            <div>
                <h1>Home Page English</h1>
                <Container>
                    <Row>
                        <Col>
                            <Form onSubmit={this.addPost}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Article id</Form.Label>
                                    <Form.Control name="id" type="text" placeholder="enter-article-id" />
                                </Form.Group>

                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Home Page Category</Form.Label>
                                    <Form.Control name="category" type="text" placeholder="enter-home-page-category" />
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        </Col>
                        <Col>
                        <br/>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>id</th>
                                    <th>title</th>
                                    <th>cat</th>
                                    <th>Date</th>
                                    <th>url</th>
                                    <th>language</th>
                                    <th>delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    elements.map(e => {
                                        return(
                                            <tr>
                                                <td>{e.id}</td>
                                                <td>{e.title}</td>
                                                <td>{e.cat}</td>
                                                <td>{e.published_time}</td>
                                                <td>{e.url_id}</td>
                                                <td>{e.language}</td>
                                                <td><Button onClick={() => {this.deletePost(e.id)}}>del</Button></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default HomePageSettings;