import React, { Component } from 'react';
import { Image, Carousel, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Redirect } from '@reach/router';
import { Auth, Hub } from 'aws-amplify';
import logo from './Logo-nbg.png';
import './nav.css';

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hasUser: false,
            clickSignup: false,
        }
        this.onClickSignUp = this.onClickSignUp.bind(this);
    }
    

    async componentDidMount() {   
        await Auth.currentSession()
        .then((data) => {
            this.setState({
                hasUser: true,
                clickSignup: false,
            })
        }).catch((err) => {
            this.setState({
                hasUser: false,
                clickSignup: false,
            })
        });
    }

    async onClickSignUp() {
        console.log('on click sign up')
        await Auth.currentSession()
        .then((data) => {
            console.log(data)
            this.setState({
                hasUser: true,
                clickSignup: true,
            })
        }).catch((err) => {
            this.setState({
                hasUser: false,
                clickSignup: true,
            })
        });
    }

    render() {
        if(this.state.hasUser === true && this.state.clickSignup === true) {
            return (<Redirect from="/home" to="/writer/apply" noThrow/>)
        } else if(this.state.hasUser === false && this.state.clickSignup === true) {
            return (<Redirect from="/home" to="/signin" noThrow/>)
        }
        return (
            <div>
                <div >
                    <div className="bottomLine">
                        {(this.state.hasUser) ? (
                                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}} className="signInLine" >
                                    <Button variant="dark" style={{backgroundColor: 'black'}} onClick={this.onClickSignUp}>
                                        <label style={{color: '#ffca18'}}>
                                            {(!this.state.hasUser) ? 'SIGN UP NOW' : 'Become a Writer'}
                                        </label>
                                    </Button>
                                    <p style={{color: '#000000'}}>
                                        Click 'Become a writer' button, fill the form and submit. We will let you know soon.
                                    </p>
                                </div>
                            ) : (
                                <Carousel>
                                    <Carousel.Item>
                                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}} className="topline">
                                            <Image class="justify-content-center" src={logo} width="300" height="250"/>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}} className="middleLine">
                                            <label style={{color: 'black'}}>Where writing goes next decade?</label>
                                        </div>
                                        <Carousel.Caption>
                                            <h3 style={{color: 'black'}}>Join With Lihini</h3>
                                            <p style={{color: 'black'}}>Be a lihinian. Become a professionl writer</p>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}} className="signInLine">
                                            <Button variant="dark" style={{backgroundColor: 'black'}} onClick={this.onClickSignUp}>
                                                <label style={{color: '#ffca18'}}>
                                                    {(!this.state.hasUser) ? 'SIGN UP NOW' : 'Become a Writer'}
                                                </label>
                                            </Button>
                                        </div>
                                        <Carousel.Caption>
                                            <h3 style={{color: 'black'}}>Join With Lihini</h3>
                                            <p style={{color: 'black'}}>Be a lihinian. Become a professionl writer</p>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                </Carousel>
                        )
                    }
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;