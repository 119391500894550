import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './article.css';


function Tags() {
    var tags = [];
    var tagNames = [];
    tagNames = ["DataScience", "Machine Learning", "Health", "Food"];
    var tagLinks = [];
    tagLinks = ["dataScience", "machine learning"]

    for (var i = 0; i <= tagNames.length - 1; i++) {
        tags.push(<Col style={{ margin: "10px" }}>
            <a href={tagLinks[i]} style={{ marginBottom: "10px", marginTop: "10px", color: "grey" }}>{tagNames[i]}</a>
        </Col>)
    }

    return (
        <>
            {tags}
        </>
    )
}






class ArticlePreview extends React.Component {
  

    render() {
        const content = this.props.content;
        const writerImage = this.props.writerImage;
        const date = this.props.date;
        const username = this.props.username;
        console.log(content);
        return (
            <div>
                <Container fluid responsive style={{}} >
                    {/* writer name and other details for lg and xl screens */}
                    <Row style={{ marginTop: "10px" }}>

                        <Col md={3} xl={2} lg={2} xs={12} style={{ paddingLeft: "20px" }} className="d-sm-none d-none d-xs-block d-md-none          d-lg-none d-lg-block"   >

                            <Row style={{ height: "200px" }} className="d-none d-sm-block d-none d-xs-block d-none d-md-block d-none d-lg-block" ></Row>
                            <a href="#image"><img src={writerImage} alt="author" width="98" height="98" className="authorImage"></img></a>
                            <h5 style={{ color: "grey", marginTop: "10px" }}>Written by:</h5>
                            <p style={{ marginTop: "8px", fontFamily: "sans-serif", fontWeight: "550", marginBottom: "2px" }}> <a href="#name" style={{ color: "black" }}>{username}</a></p>
                            <p style={{ textAlign: "left", fontFamily: "sans-serif", fontSize: "12px" }}><nobr>{new Date(date).toLocaleDateString('en-CA')}</nobr></p>
                        </Col>

                        <Col xm={12} sm={12} md={12} lg={8} xl={8} >
                            <Row>
                                <Container>
                                    <Card border="0">
                                        <Card.Title style={{ textAlign: "center", marginBottom: "50px" }}><h1 style={{ fontWeight: "800", fontFamily: "serif" }} className="font">{this.props.title}</h1></Card.Title>
                                        {/* Author name and image for small and medium size screens */}

                                        <Row style={{ display: "flex", marginBottom: "10px",marginLeft: 3 }} className="d-xl-none d-lg-none"  >
                                            <a href="#image"><img src={writerImage} alt="author" width="78" height="78" className="authorImage"></img></a>
                                            <Col style={{ marginLeft: "5px" }}>
                                                <Row style={{ padding: "none" }}>
                                                    <p style={{ marginBottom: "5px", textAlign: "left", fontFamily: "sans-serif", fontWeight: "550" }}> <a href="#name" style={{ color: "black" }}>
                                                        <span style={{ color: "grey" }}>Written by: </span>{username}</a>
                                                    </p>
                                                </Row>
                                                <Row style={{ padding: "none" }}>
                                                </Row>
                                                <Row style={{ padding: "none" }}>
                                                    <p style={{ paddingRight: "5px", textAlign: "left", fontFamily: "sans-serif", fontSize: "12px" }}><nobr>{new Date(date).toLocaleDateString('en-CA')}</nobr></p>
                                                </Row>
                                            </Col>
                                        </Row>



                                        <a href="#image"> <Card.Img variant="top" alt="article" src={this.props.thumbnail} className="card-image" style={{ objectFit: "cover",marginBottom: "20px" }} /></a>
                                        <Card.Text style={{ fontFamily: "sans-serif" }}>
                                        <b style={{ color: "black", textAlign: "justify" }} className="description">" {this.props.description} "</b>

                                            {content.map((i) => {
                                                if (i.type === "para") {
                                                    return <p className="para" style={{ textAlign: "justify" }}>{i.content}</p>

                                                } else if (i.type === "subtitle") {
                                                    return <h2 className="subtitle">{i.content}</h2>

                                                } else if (i.type === "img") {
                                                    return <><img src={i.content} /><br/> <i className="caption" >{i.caption}</i></>

                                                } else { return <p>{i.content}</p> }
                                            }
                                            )}
                                        </Card.Text>
                                    </Card>
                                    {/* <Row style={{ marginBottom: "20px" }}>
                                        <Tags />
                                    </Row> */}

                                </Container>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default ArticlePreview;