import React, {  } from 'react';
import { Link } from '@reach/router'
import { Row, Col, Button } from 'react-bootstrap';
import './article.css';
import ArticlePreview from './ArticlePreview'
import { AdminContext } from '../../context/AdminContext'




export default class ArticleReadyToPublish extends React.Component {
    static contextType = AdminContext;
    constructor(props) {
        super(props);
        this.state = {
            item: null
        }
    }
    render() {
        const { readyToPublish } = this.context;
        const item = readyToPublish.find(i => i.id === this.props.id);
        if (item === null || item === undefined) { return (<div></div>) }
        var json = JSON.parse(item.content);
        return (
            <><ArticlePreview 
            title={item.title} 
            thumbnail={item.thumbnail}
            owner={item.owner} 
            writerImage={item.writer.profile_picture} 
            username={item.writer.user_name} 
            date={item.createdAt} 
            content={json.elements}
            description={item.description} />
                <Row>
                    <Col md={3} xl={2} lg={2} xs={12} sm={12}></Col>
                    <Col xm={12} sm={12} md={9} lg={8} xl={8} style={{ marginLeft: "10%" }}>
                        <Link to={`/admin/publish-article/${item.id}`} ><Button style={{ margin: "10px" }} >Publish</Button></Link>
                    </Col>
                </Row></>
        );
    }
}








