import React, {  } from 'react';
import { Link } from '@reach/router'
import { Row, Col, Button } from 'react-bootstrap';
import './article.css';
import ArticlePreview from './ArticlePreview'
import { AdminContext } from '../../context/AdminContext'



class ArticleOnReview extends React.Component {
    static contextType = AdminContext;
    constructor(props) {
        super(props);
        this.state = {
            item: null
        }
    }


    render() {
        const { onReview } = this.context;
        const item = onReview.find(i => i.id === this.props.id);
        if (item === null || item === undefined) { return (<div></div>) }
        var json = JSON.parse(item.content);
        return (
            <>
                <ArticlePreview
                    title={item.title}
                    author={item.author}
                    thumbnail={item.thumbnail}
                    owner={item.owner}
                    writerImage={item.writer.profile_picture}
                    username={item.writer.user_name}
                    date={item.createdAt}
                    content={json.elements} 
                    description={item.description}/>
                <Row>
                    <Col md={3} xl={2} lg={2} xs={12} sm={12}></Col>
                    <Col xm={12} sm={12} md={9} lg={8} xl={8} style={{ marginLeft: "10%" }}>
                        <Link to="/edit/article/content" state={{ id: item.id }} > <Button variant="outline-success" style={{ margin: "10px", borderRadius: "20px" }} >Edit</Button></Link>
                        <Link to={`/admin/reject-article/${item.id}`} > <Button variant="outline-success" style={{ margin: "10px", borderRadius: "20px" }} src="#reject">Reject</Button></Link>
                        <Link to={`/admin/ready-to-publish-article/${item.id}`}  ><Button variant="outline-success" style={{ margin: "10px", borderRadius: "20px" }} src="#reject">Ready to publish</Button></Link>
                    </Col>
                </Row>
            </>
        );
    }
}

export default ArticleOnReview;