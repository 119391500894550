/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:44273b43-e34d-4e3e-9720-c790d853c6bc",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_n6p3IWsLv",
    "aws_user_pools_web_client_id": "6nmg7f5ftm9s0ak52e5p3ro33g",
    "oauth": {},
    "aws_user_files_s3_bucket": "lihinimediaarticleimages141625-prod",
    "aws_user_files_s3_bucket_region": "ap-southeast-1",
    "aws_appsync_graphqlEndpoint": "https://zjkr4o62rbhsno2lhqzf6se5iy.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-hewrypkqu5b7lazj4eu7saockm"
};


export default awsmobile;
