import React, { Component } from 'react';
import { Form, Button, Container, Row, Col, InputGroup } from 'react-bootstrap';
import { CaretRightSquareFill } from 'react-bootstrap-icons'
import { API, graphqlOperation } from 'aws-amplify';
import { getUser } from './../../../api/queries';
import { updateUser } from './../../../api/mutations';
import { Auth } from 'aws-amplify';
import UploadImage from './UploadImage'
import { Redirect } from '@reach/router'

class WriterProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            writerId: null,
            profile_picture: null,
            user_name: null,
            facebook: null,
            youtube: null,
            linkedin: null,
        }
        this.setImageProfile = this.setImageProfile.bind(this);
        this.saveProfile = this.saveProfile.bind(this);
    }
    

    async componentDidMount() {
        const userInfo = await Auth.currentUserInfo()
        const result = await API.graphql(graphqlOperation(getUser, {id: userInfo.username}));
        const user = result.data.getUser;
        
        //console.log(user);
        this.setState({
            writerId: userInfo.username,
            profile_picture: user.profile_picture,
            user_name: user.user_name,
            facebook: user.facebook,
            youtube: user.youtube,
            linkedin: user.linkedin,
            bio: user.bio,
            submitted: false,
        })
    }

    setImageProfile(url) {
        this.setState({
            profile_picture: url
        });
    }

    async saveProfile(e) {
        e.preventDefault();
        // console.log(e.target);
        const target = e.target;
        var param = {
            id: this.state.writerId,
            profile_picture: this.state.profile_picture,
            user_name: target.user_name.value ? target.user_name.value : this.state.user_name,
            facebook: target.facebook.value ? target.facebook.value: this.state.facebook,
            youtube: target.youtube.value ? target.youtube.value: this.state.youtube,
            linkedin: target.linkedin.value ? target.linkedin.value: this.state.linkedin,
            bio: target.bio.value ? target.bio.value: this.state.bio,
        }

        //console.log(param);
        try {
            await API.graphql(graphqlOperation(updateUser, {input : param}));
            this.setState({
                submitted: true
            })
        //console.log(result);
        } catch (e) {
            //console.log(e)
        }
    }

    render() {
        const { user_name, bio, facebook, youtube, linkedin, profile_picture } = this.state;
        console.log(this.state.submitted);
        if( this.state.submitted === true) { return ( <Redirect from="/" to="/" noThrow/>)}
        return (
            <Container>
                <Row>
                    <Col md={{ span: 6, offset: 3 }}>
                    <Form onSubmit={this.saveProfile}>
                        <p> Profile Picture </p>
                        <UploadImage value={profile_picture} notify={this.setImageProfile} />
                        <br/>
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Display Name</Form.Label>
                            <Form.Control name="user_name"  ref="user_name" placeholder={user_name} />
                        </Form.Group>
                        <br/>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>bio</Form.Label>
                            <Form.Control as="textarea" name="bio" placeholder={bio} />
                        </Form.Group>
                        <br/>
                        <br/>
                        <p> Link your social accounts</p>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroupPrepend"><CaretRightSquareFill/></InputGroup.Text>
                                <Form.Control name="youtube" placeholder={youtube} />
                            </InputGroup.Prepend>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroupPrepend"><b>{'\u00A0'}In</b></InputGroup.Text>
                                <Form.Control name="linkedin" placeholder={linkedin} />
                            </InputGroup.Prepend>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroupPrepend"><b>{'\u00A0'}F{'\u00A0'}</b></InputGroup.Text>
                                <Form.Control name="facebook" placeholder={facebook} />
                            </InputGroup.Prepend>
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                        {'\u00A0'}{'\u00A0'}<b>Note : Anyone can see this information. </b>
                    </Form>
                    <div>
                        <br></br>
                        <br></br>
                        
                    </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default WriterProfile;<p>this is profile page</p>