import React, { Component } from 'react';
import { DevContext } from './../context/DevContext';
import { Table, Card, Container, Row, Col } from 'react-bootstrap';
import { BlockquoteLeft } from 'react-bootstrap-icons';

class TeamMember extends Component {
    static contextType = DevContext;
    render() {
        var { teamMemberList } = this.context
        if(teamMemberList === undefined) {
            teamMemberList = new Array();
        }
        return (
            <di>
                <h3 style={{fontColor:'balack'}}>TEAM MEMBERS</h3>
            <Container>
                <Row >
                
                {
                    teamMemberList.map((item) => {
                        console.log(item);
                        let profile_picture = './backgroudi.jpg';
                        if("https://www.example.com" !== item.userId.profile_picture) {
                            profile_picture = item.userId.profile_picture;
                        }
                        return (
                            <div style={{marginBottom: 10}}>
                                <Col xl={2} lg={2} md={2} sm={12} xs={12}>
                                    <Card className="bg-dark text-white" style={{ width: 180, height: 180,}}>
                                        <Card.Img src={profile_picture}  style={{ width: 180, height: 180,}} alt="Card image" />
                                        <Card.ImgOverlay>
                                            <Card.Title style={{backgroundColor: "#000000", fontSize: 13}}>{item.full_name}</Card.Title>
                                            <Card.Text style={{backgroundColor: "#000000", fontSize: 11}}>
                                                {item.userId.email}
                                            </Card.Text>
                                            <Card.Text></Card.Text>
                                        </Card.ImgOverlay>
                                    </Card>
                                </Col>
                            </div>
                        )
                    })
                }
                </Row>
            </Container>
            </di>
        );
    }
}

export default TeamMember;