import React, { Component } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { CaretRightSquareFill, GearFill, FileEarmarkPersonFill } from 'react-bootstrap-icons';
import { Link } from '@reach/router' 
import 'bootstrap/dist/css/bootstrap.min.css';
import CreateArticleSection from './home/CreateArticleSection';
import { API, graphqlOperation, Auth } from "aws-amplify";
import { getUser } from './../../api/queries';
import Section from './home/Section';
import Loading from '../common/Loading'
import { ARTICLCE_TYPE, ARTICLCE_TYPE_NAME, ARTICLCE_TYPE_SUB } from '../../constants/constants';
import './style.css'
import { WriterContext } from '../context/WriterContext';

class WriterHome extends Component {
    static contextType = WriterContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: {},
            profile: {}
        }
        this.getMyDrafted = this.getMyDrafted.bind(this);
    }

    componentDidMount() {
        console.log('logging props here ', this.context)
        this.getMyDrafted();
    }

    async getMyDrafted() {
        const userInfo = await Auth.currentUserInfo()
        var writerId = userInfo.username
        const userresult = await API.graphql(graphqlOperation(getUser, {id: writerId}));
        const user = userresult.data.getUser;
        
        console.log(user);
        this.setState({
            loading: false,
            //data: {drafted, onReview},
            profile: {
                ...user
            }
        })
    }

    render() {
        console.log(this.state);
        const { drafted, published, onReview, onProcess, readyToPublish, rejected } = this.context;
        const { profile } = this.state;
        return (
            <Container className="backgroudgray" fluid>
                {(this.state.loading) ? <Loading/> :
                    <Container>
                        <Row>
                            <Col sm={4}>
                                <Container fluid class="fixed">
                                    <h2>{profile.user_name.toUpperCase()}</h2>
                                    <p>{profile.bio}</p>
                                    <Image src={profile.profile_picture} fluid/>
                                    <br/>
                                    <br/>
                                    <Link to="/profile/edit" from="/" style={{marginRight: 20, color: 'black'}}><GearFill/></Link>
                                    <Link to="/wirter/view" from="/" style={{marginRight: 20, color: 'black'}}><FileEarmarkPersonFill/></Link>
                                    <hr/>
                                    <h3>Social links</h3>
                                    <p>Anyone can see this links</p>
                                        <a href={profile.facebook}><b>F</b> Facebook</a>
                                        <br/><br/>
                                        <a href={profile.youtube}><CaretRightSquareFill/> YouTube</a>
                                        <br/><br/>
                                        <a href={profile.linkedin}><b>In</b> Linkedin</a>
                                    <hr/>
                                </Container>
                            </Col>
                            
                            <Col sm={8}>
                                <CreateArticleSection/>
                                <p>Notice: Statistics and some functionalities are disabled.! Will be updating soon!</p>
                                <p>Guide: Read <Link to="/article/guide" from="/" >best practices</Link> guide for create article!</p>
                                <hr/>
                                <Section data={drafted} type={ARTICLCE_TYPE.DRAFTED} title={ARTICLCE_TYPE_NAME.DRAFTED} sub={ARTICLCE_TYPE_SUB.DRAFTED}/>
                                <Section data={published} type={ARTICLCE_TYPE.PUBLISHED} title={ARTICLCE_TYPE_NAME.PUBLISHED} sub={ARTICLCE_TYPE_SUB.PUBLISHED}/>
                                <Section data={onReview} type={ARTICLCE_TYPE.ONREVIEW} title={ARTICLCE_TYPE_NAME.ONREVIEW} sub={ARTICLCE_TYPE_SUB.ONREVIEW}/>
                                <Section data={readyToPublish} type={ARTICLCE_TYPE.READYTOPUBLISH} title={ARTICLCE_TYPE_NAME.READYTOPUBLISH} sub={ARTICLCE_TYPE_SUB.READYTOPUBLISH}/>
                                <Section data={rejected} type={ARTICLCE_TYPE.RJECTED} title={ARTICLCE_TYPE_NAME.RJECTED} sub={ARTICLCE_TYPE_SUB.RJECTED}/>
                            </Col>
                        </Row>
                    </Container>
                }
            </Container>
        );
    }
}

export default WriterHome;