import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from '@reach/router';

class DevNavBar extends Component {
    render() {
        return (
            <div>
                <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                    <Navbar.Brand href="https://www.lihini.media.com" >Home</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                        <Nav.Link style={{color: '#ffca18'}}>
                            <Link style={{color: '#ffffff'}} to='/signin'>
                                SIGN IN
                            </Link>
                        </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        );
    }
}

export default DevNavBar;