import React, {  } from 'react'
import { Card } from 'react-bootstrap';
import './articleCard.css';
import { Link } from '@reach/router'


export default function ArticleCard(props) {
    const thumbnail = props.thumbnail;
    const username = props.username;
    const title = props.title;
    const category = props.category;
    const date = props.date;

    return (
        <>
            <Card borderless style={{ borderRadius: "12px", border: "none" }}>
                <Card.Body style={{ paddingRight: "0", paddingLeft: "0", paddingTop: "0" }}>

                    <Link to={props.link} >
                        <Card.Img variant="top" alt="thumbnail" src={thumbnail} className="card-image" width="300" height="200" style={{ objectFit: "cover" }} />
                    </Link>


                    <div className="small-card-text-area " style={{ marginRight: "10px" }}>
                        <Card.Text >
                            <div className="row " style={{ textAlign: "left", marginLeft: "-10px", marginTop: "3px" }}>
                                <div className="col " >
                                    <p className="p" style={{ marginTop: "2px", marginBottom: "2px" }}><a href="#author" style={{ color: "black" }}>{username}</a></p>
                                </div>
                            </div>
                            <div className="row " style={{ textAlign: "left", marginLeft: "-10px" }}>
                                <div className="col ">
                                    <p className="p" style={{ fontSize: "15px", fontWeight: "650", marginTop: "2px", marginBottom: "2px" }}><Link to="#" style={{ color: "black" }}>{title}</Link></p>
                                </div>
                            </div>
                            <div className="row" style={{ textAlign: "left", marginLeft: "-10px" }}>
                                <div className="col " >
                                    <p className="p" style={{ marginTop: "2px", marginBottom: "2px", fontWeight: "550" }} ><a href="#category" style={{ color: "black" }}>{category}</a></p>
                                </div>
                            </div>
                            <div className="row" style={{ textAlign: "left", marginLeft: "-10px" }}>
                                <div className="col " >
                                    <p className="p" style={{ marginTop: "2px", marginBottom: "2px", textAlignLast: "right" }}><span>{date}</span></p>
                                </div>
                            </div>
                        </Card.Text>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}




