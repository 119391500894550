import React, { Component } from 'react'
import { Col, Row, Container, Table, Button } from 'react-bootstrap';
import { Link } from '@reach/router'
import { AdminContext } from '../../context/AdminContext'


export default class index extends Component {
    static contextType = AdminContext;

    render() {
        const { writerList } = this.context;
        console.log(writerList);
        return (
            <>

                <Container style={{ backgroundColor: "", display: "table", width: "100%", height: "100%" }}>
                    <Row>
                        <Col xl={2} lg={2} md={2} sm={12} xs={12} style={{ backgroundColor: "" }}>
                            <Row style={{ marginTop: "60px" }}><Link to="/admin">Writers</Link></Row>
                            <Row><Link to="/articles">Articles</Link></Row>
                            <Row><Link to="/developer">Developer</Link></Row>
                            <Row><Link to="/create/article">Create Article</Link></Row>
                            <Row><Link to="/published">Published Articles</Link></Row>
                        </Col>
                        <Col xl={10} lg={10} md={10} sm={12} xs={12} >
                            <Row>
                                <Col>
                                    <Row>
                                        <Table  >
                                            <thead>
                                                <tr style={{ fontSize: "15px" }}>
                                                    <th></th>
                                                    <th >Writer id</th>
                                                    <th >Name</th>
                                                    <th style={{ textAlign: "center" }} colSpan="2">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {writerList.map((writer) =>
                                                    <tr key={writer.id} >
                                                        <td>
                                                            <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-person-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                                            </svg>
                                                        </td>
                                                        <td>{writer.id}</td>
                                                        <td>{writer.full_name}</td>

                                                        <td><Link to={`/admin/writer/view/${writer.id}`} ><Button variant="outline-success" style={{ margin: "10px",borderRadius:"20px" }}   >View</Button></Link></td>
                                                        <td><Link to={`/admin/writer/edit/${writer.id}`}><Button variant="outline-success" style={{ margin: "10px",borderRadius:"20px" }} >Edit</Button></Link></td>
                                                    </tr>
                                                )
                                                }
                                            </tbody>
                                        </Table>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}


