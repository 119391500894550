import React, { Component } from 'react'
import { Container, Form, Button } from 'react-bootstrap'

export default class TeamMemberForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isValidated: false
        }
        this.setValidate = this.setValidate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    setValidate(b) {
        this.setState({
            isValidated: true
        });
    }

    async handleSubmit(e) {
        e.preventDefault();
        this.setValidate(true);

        const form = e.currentTarget.elements;


        if (e.currentTarget.checkValidity() === false) {
            return
        }
        if (!form.email.value || !form.nic.value || !form.address.value || !form.contact.value || !form.bankName.value || !form.accoutNo.value) {
            return
        }
        if (!form.gender.value || !form.userRole.value || !form.writerType.value) {
            return
        }



        var param = {
            email : form.email.value,
            nic : form.nic.value,
            address : form.address.value,
            contact : form.contact.value,
            gender : form.gender.value,
            bankName : form.bankName.value,
            accoutNo : form.accoutNo.value,
            userRole : form.userRole.value,
            writerType : form.writerType.value
        }



    }


    render() {
        console.log(this.props.user);
        //const { email } = this.props.user
        return (
            <div style={{ fontFamily: 'Monospace'}}>
                <Container fluid >
                    <Form validated={this.state.isValidated} onSubmit={this.handleSubmit} style={{ paddingLeft: "10%", paddingRight: "10%" }}>
                    <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control name="name" type="email" placeholder="Enter email" required />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control name="email" type="email" placeholder="Enter email" required />
                        </Form.Group>
                        <Form.Group controlId="formBasicID">
                            <Form.Label>NIC</Form.Label>
                            <Form.Control name="nic" type="text" placeholder="Please provide your NIC number" required />
                        </Form.Group>
                        <Form.Group controlId="formGridAddress1">
                            <Form.Label>Address</Form.Label>
                            <Form.Control name="address" type="text" placeholder="Please provide your address" />
                        </Form.Group>
                        <Form.Group controlId="formBasicContact">
                            <Form.Label>Contact Number</Form.Label>
                            <Form.Control name="contact" type="text" placeholder="Please provide your contact number" required />
                        </Form.Group>
                        <Form.Group controlId="exampleForm.SelectCustom">
                            <Form.Label>Gender</Form.Label>
                            <Form.Control name="gender" as="select" custom>
                                <option>Male</option>
                                <option>Female</option>
                                <option>Other</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="formBasicBank">
                            <Form.Label>Bank Name</Form.Label>
                            <Form.Control name="bankname" type="text" placeholder="Please provide bank name" />
                        </Form.Group>
                        <Form.Group controlId="formBasicBankAcc">
                            <Form.Label>Account Number</Form.Label>
                            <Form.Control name="accountNo" type="text" placeholder="Please provide account number" />
                        </Form.Group>
                        <Form.Group controlId="exampleForm.SelectCustom">
                            <Form.Label>User Role</Form.Label>
                            <Form.Control name="userRole" as="select" custom>
                                <option>Writer</option>
                                <option>Admin</option>
                                <option>Admin-Support</option>
                                <option>Reader</option>
                                <option>Owner</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="exampleForm.SelectCustom">
                            <Form.Label>Writer Type</Form.Label>
                            <Form.Control name="writerType" as="select" custom>
                                <option>Hired</option>
                                <option>Freelancing</option>
                                <option>Contributing</option>
                            </Form.Control>
                        </Form.Group>

                        <Button variant="primary" type="submit" >
                            Submit
                        </Button>
                    </Form>
                </Container>
            </div>
        )
    }
}

