import React, { Component } from 'react';
import './style.css';

export default class NetworkError extends Component {
    render() {
        return (
            <div className="centererror">
                <h2>Network Error</h2>
                <h3>404</h3><br/>
	            <b><p>Sorry, This page isn’t available.</p></b>
                The link you followed may be broken, or the page may have be removed.Go back to<a href ="https://www.lihini.media.com">Lihini.Media</a>
            </div>
        )
    }
}
