import ArticleCard from './ArticleCard'
import { Col, Row } from 'react-bootstrap'
import './articleCard.css';
import { AdminContext } from '../../context/AdminContext'
import React, { Component } from 'react'

const initialCardValue  = 4;

export default class ArticleCategory extends Component {
    static contextType = AdminContext;
    constructor(props) {
        super(props);
        this.state = {
            endValueFrOnreview: initialCardValue,
            endValueFrReadyToPublish: initialCardValue,
            endValueFrRejected: initialCardValue,
            endValueFrDrafted: initialCardValue,
        };

        this.handleOnReview = this.handleOnReview.bind(this);
        this.handleReadyToPublish = this.handleReadyToPublish.bind(this);
        this.handleRejected = this.handleRejected.bind(this);
    }


    handleOnReview = () => {
        this.setState({
            endValueFrOnreview: this.state.endValueFrOnreview + 4
        });
    }

    handleReadyToPublish = () => {
        this.setState({
            endValueFrReadyToPublish: this.state.endValueFrReadyToPublish + 4
        });
    }

    handleRejected = () => {
        this.setState({
            endValueFrRejected: this.state.endValueFrRejected + 4
        });
    }

    handleDrafted = () => {
        this.setState({
            endValueFrDrafted: this.state.endValueFrDrafted + 4
        });
    }

    render() {
        const { readyToPublish, onReview, rejected, draftedToWriter } = this.context;

        return (
            <>
                {/* ready to publish */}
                <h3 style={{ marginTop: "20px", marginBottom: "20px" }}>Ready To Publish : {Object.keys(readyToPublish).length}</h3>
                {Object.keys(readyToPublish).length === 0 ? <p>No items!</p> : <>
                    <Row>
                        {readyToPublish.slice(0, this.state.endValueFrReadyToPublish).map((item) => <>
                            <Col xl={3} lg={3} md={6} sm={12} xs={12}>
                                <ArticleCard
                                    link={`/admin/view/ready-to-publish-article/${item.id}`}
                                    author={item.author}
                                    title={item.title}
                                    date={item.date}
                                    category={item.category}
                                    thumbnail={item.thumbnail}
                                    owner={item.owner}
                                    writerImage={item.writer.profile_picture}
                                    username={item.writer.user_name} />
                            </Col>
                        </>
                        )
                        }
                    </Row>
                    {Object.keys(readyToPublish).length > 4 ?
                        <div style={{ marginTop: "20px" }}>
                            <a href="#more articles" onClick={this.handleReadyToPublish}>more articles</a>
                        </div>
                        : ""}
                </>
                }


                {/* onreview */}
            <h3 style={{ marginTop: "20px", marginBottom: "20px" }}>On Review : {Object.keys(onReview).length}</h3>
                {Object.keys(onReview).length === 0 ? <p>No items!</p> : <>
                    <Row>
                        {onReview.slice(0, this.state.endValueFrOnreview).map((item) =>
                            <Col xl={3} lg={3} md={6} sm={12} xs={12}>
                                <ArticleCard
                                    link={`/admin/view/on-review-article/${item.id}`}
                                    author={item.author}
                                    title={item.title}
                                    date={item.date}
                                    category={item.category}
                                    thumbnail={item.thumbnail}
                                    owner={item.owner}
                                    writerImage={item.writer.profile_picture}
                                    username={item.writer.user_name} />
                            </Col>)
                        }
                    </Row>
                    {Object.keys(onReview).length > 4 ?
                        <div style={{ marginTop: "20px" }}>
                            <a href="#more articles" onClick={this.handleOnReview}>more articles</a>
                        </div>
                        : ""}
                </>
                }


                {/* rejected */}

            <h3 style={{ marginTop: "20px", marginBottom: "20px" }}>Rejected: {Object.keys(rejected).length}</h3>
                {Object.keys(rejected).length === 0 ? <p>No items!</p> : <>
                    <Row>
                        {rejected.slice(0, this.state.endValueFrRejected).map((item) =>
                            <Col xl={3} lg={3} md={6} sm={12} xs={12}>
                                <ArticleCard
                                    link={`/admin/view/rejected-article/${item.id}`}
                                    author={item.author}
                                    title={item.title}
                                    date={item.date}
                                    category={item.category}
                                    thumbnail={item.thumbnail}
                                    owner={item.owner}
                                    writerImage={item.writer.profile_picture}
                                    username={item.writer.user_name} />
                            </Col>)
                        }
                    </Row>
                    {Object.keys(rejected).length > 4 ?
                        <div style={{ marginTop: "20px" }}>
                            <a href="#more articles" onClick={this.handleRejected}>more articles</a>
                        </div>
                        : ""}
                </>
                }

                <h3 style={{ marginTop: "20px", marginBottom: "20px" }}>On Review : {Object.keys(draftedToWriter).length}</h3>
                    {Object.keys(draftedToWriter).length === 0 ? <p>No items!</p> : <>
                        <Row>
                            {draftedToWriter.slice(0, this.state.endValueFrOnreview).map((item) =>
                                <Col xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <ArticleCard
                                        link={`/admin/view/on-review-article/${item.id}`}
                                        author={item.author}
                                        title={item.title}
                                        date={item.date}
                                        category={item.category}
                                        thumbnail={item.thumbnail}
                                        owner={item.owner}
                                        writerImage={item.writer.profile_picture}
                                        username={item.writer.user_name} />
                                </Col>)
                            }
                        </Row>
                        {Object.keys(draftedToWriter).length > 4 ?
                            <div style={{ marginTop: "20px" }}>
                                <a href="#more articles" onClick={this.handleOnReview}>more articles</a>
                            </div>
                            : ""}
                    </>
                }


            </>
        )
    }
}

