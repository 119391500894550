export const getImageUrl = /* GraphQL */ `
  query GetImageUrl($imgType: String!) {
    getImageUrl(imgType: $imgType) {
      link
      name
      location
    }
  }
`;
export const getDraftedArticle = /* GraphQL */ `
  query GetDraftedArticle($id: ID!) {
    getDraftedArticle(id: $id) {
      id
      title
      thumbnail
      language
      category
      language_category
      description
      content
      resources
      time
      b_type
      paid_free
      writer_id
      state
      createdAt
      updatedAt
      owner
    }
  }
`;
export const draftedArticleByWriter = /* GraphQL */ `
  query DraftedArticleByWriter(
    $writer_id: String
    $time: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDraftedArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    draftedArticleByWriter(
      writer_id: $writer_id
      time: $time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        thumbnail
        language
        category
        language_category
        description
        content
        resources
        time
        b_type
        paid_free
        writer_id
        state
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      user_name
      current_position
      bio
      facebook
      linkedin
      youtube
      tiktok
      profile_picture
      owner
    }
  }
`;
export const listWriterRequests = /* GraphQL */ `
  query ListWriterRequests(
    $filter: ModelWriterRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWriterRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        extra
        createdAt
        updatedAt
        userId {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          profile_picture
          owner
          createdAt
          updatedAt
        }
        owner
      }
      nextToken
    }
  }
`;
export const listDraftedArticles = /* GraphQL */ `
  query ListDraftedArticles(
    $filter: ModelDraftedArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDraftedArticles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        thumbnail
        language
        category
        language_category
        description
        content
        resources
        time
        b_type
        paid_free
        writer_id
        state
        createdAt
        updatedAt
        owner
        writer {
          id
          email
          user_name
          bio
          facebook
          linkedin
          youtube
          tiktok
          profile_picture
          owner
          createdAt
          updatedAt
        }
        corrected_by {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          profile_picture
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listTeamMembers = /* GraphQL */ `
  query ListTeamMembers(
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        full_name
        language
        category
        address
        gender
        phone_number
        bank_acc
        bank_name
        nic
        writer_type
        user_role
        extra
        createdAt
        updatedAt
        userId {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          profile_picture
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;

export const listWriterRequestsForUser = /* GraphQL */ `
  query ListWriterRequests(
    $filter: ModelWriterRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWriterRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId {
          id
        }
        owner
      }
      nextToken
    }
  }
`;

export const getHomePageContent = /* GraphQL */ `
  query GetHomePageContent($id: ID!) {
    getHomePageContent(id: $id) {
      id
      data
      createdAt
      updatedAt
    }
  }
`;
export const getPublishedArticle = /* GraphQL */ `
  query GetPublishedArticle($id: ID!) {
    getPublishedArticle(id: $id) {
      id
      title
      thumbnail
      language
      category
      language_category
      description
      url_id
      published_time
      resources
      content
      views {
        id
      }
    }
  }
`;

export const listPublishedArticles = /* GraphQL */ `
  query ListPublishedArticles(
    $filter: ModelPublishedArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublishedArticles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        thumbnail
        language
        category
        language_category
        description
        content
        resources
        created_time
        writer_id
        url_id
        published_time
        createdAt
        updatedAt
        writer {
          id
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
        creater {
          id
          email
          user_name
          current_position
          bio
          facebook
          linkedin
          youtube
          tiktok
          url_id
          profile_picture
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;