import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { ARTICLE_GUIDE, ARTICLE_GUIDE_MAIN_DESCR } from './../../constants/article';
import './style.css';

export default class ArticleGuide extends Component {
    render() {
        console.log(ARTICLE_GUIDE)
        return (
            <div className="backgroud">
                <Container>
                    <Row>
                        <Col  md={{ span: 7, offset: 3 }}>
                            <br/>
                            <h1>
                                Article Guide
                            </h1>
                            <p>
                            {ARTICLE_GUIDE_MAIN_DESCR}
                            </p>
                            <br/>
                            {
                                ARTICLE_GUIDE.map((element) => (
                                    <Para element={element}/>
                                ))
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}


const Para = ({element}) => {
    return (
        <div>
            <h3> {element.title}</h3>
            <br/>
            <p style={{marginLeft: 30}}> {Object.keys(element.content).map((key, value) => {
                return (
                    <div>
                        <h5> {key} </h5>
                        {Object.keys(element.content[key]).map((k, v) => {
                            return (
                                <div>
                                    <p>* { k } </p>
                                </div>
                                );
                        })}
                    </div>
                    );
            })}</p>
            <br/>
        </div>
    )
}