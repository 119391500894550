import React, { Component, createContext } from 'react'
import { graphqlOperation, API } from 'aws-amplify'
import { listDraftedArticles, listTeamMembers, listPublishedArticles } from '../../api/queries'
import { ARTICLCE_TYPE, USER_ROLE } from './../../constants/constants'

export const AdminContext = createContext();

export default class AdminContextProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            readyToPublish: [],
            onReview: [],
            rejected: [],
            writerList: [],
            teamMemberList: []
        }
    }

    async componentDidMount() {
        try {
            const resultMembers = await API.graphql(graphqlOperation(listTeamMembers, { limit: 50 }));
            const resultArticles = await API.graphql(graphqlOperation(listDraftedArticles, { limit: 50 }));
            const teammemberResult = await API.graphql(graphqlOperation(listTeamMembers, { limit: 50 }));
            const resultPublishedArticle = await API.graphql(graphqlOperation(listPublishedArticles, { limit: 50 }));
            const articleItems = resultArticles.data.listDraftedArticles.items;
            const memberItems = resultMembers.data.listTeamMembers.items;
            let filteredReadyToPublish = articleItems.filter(i => i.state === ARTICLCE_TYPE.READYTOPUBLISH);
            let filteredOnReview = articleItems.filter(i => i.state === ARTICLCE_TYPE.ONREVIEW);
            let filteredRejected = articleItems.filter(i => i.state === ARTICLCE_TYPE.RJECTED);
            let writers = memberItems.filter(i => i.user_role === USER_ROLE.WRITER);
            let draftedToWriter = articleItems.filter(i => i.state === ARTICLCE_TYPE.DRAFTED);
            console.log(writers);
            this.setState({
                readyToPublish: filteredReadyToPublish,
                onReview: filteredOnReview,
                rejected: filteredRejected,
                writerList: writers,
                teamMemberList: teammemberResult.data.listTeamMembers.items,
                draftedToWriter,
                publishedArticles: resultPublishedArticle.data.listPublishedArticles.items
            });
        } catch (err) {
            console.log('error:', err)
        }
    }


    render() {
        return (
            <>
                <AdminContext.Provider value={{ ...this.state }}>
                    {this.props.children}
                </AdminContext.Provider>
            </>
        )
    }
}
