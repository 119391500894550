export const createDraftedArticle = /* GraphQL */ `
  mutation CreateDraftedArticle(
    $input: CreateDraftedArticleInput!
    $condition: ModelDraftedArticleConditionInput
  ) {
    createDraftedArticle(input: $input, condition: $condition) {
      id
      title
      thumbnail
      language
      category
      language_category
      description
      content
      resources
      time
      b_type
      paid_free
      writer_id
      state
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateDraftedArticle = /* GraphQL */ `
  mutation UpdateDraftedArticle(
    $input: UpdateDraftedArticleInput!
    $condition: ModelDraftedArticleConditionInput
  ) {
    updateDraftedArticle(input: $input, condition: $condition) {
      id
      title
      thumbnail
      language
      category
      language_category
      description
      content
      resources
      time
      b_type
      paid_free
      writer_id
      state
      createdAt
      updatedAt
    }
  }
`;
export const deleteDraftedArticle = /* GraphQL */ `
  mutation DeleteDraftedArticle(
    $input: DeleteDraftedArticleInput!
    $condition: ModelDraftedArticleConditionInput
  ) {
    deleteDraftedArticle(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      user_name
      current_position
      bio
      facebook
      linkedin
      youtube
      tiktok
      profile_picture
      owner
    }
  }
`;
export const createWriterRequest = /* GraphQL */ `
  mutation CreateWriterRequest(
    $input: CreateWriterRequestInput!
    $condition: ModelWriterRequestConditionInput
  ) {
    createWriterRequest(input: $input, condition: $condition) {
      id
    }
  }
`;
export const changeGroup = /* GraphQL */ `
  mutation ChangeGroup(
    $userId: String!
    $groupName: String!
    $requestId: String!
    $data: AWSJSON!
  ) {
    changeGroup(
      userId: $userId
      groupName: $groupName
      requestId: $requestId
      data: $data
    ) {
      id
    }
  }
`;
export const publishArticleAndView = /* GraphQL */ `
  mutation PublishArticleAndView(
    $draftedArticleId: ID!
    $payment: Int!
    $contentSize: Int!
  ) {
    publishArticleAndView(
      draftedArticleId: $draftedArticleId
      payment: $payment
      contentSize: $contentSize
    ) {
      id
      title
      thumbnail
      language
      category
      language_category
      description
      content
      resources
      created_time
      published_time
    }
  }
`;
export const updateHomePageContent = /* GraphQL */ `
  mutation UpdateHomePageContent(
    $input: UpdateHomePageContentInput!
    $condition: ModelHomePageContentConditionInput
  ) {
    updateHomePageContent(input: $input, condition: $condition) {
      id
      data
      createdAt
      updatedAt
    }
  }
`;
export const updatePublishedArticle = /* GraphQL */ `
  mutation UpdatePublishedArticle(
    $input: UpdatePublishedArticleInput!
    $condition: ModelPublishedArticleConditionInput
  ) {
    updatePublishedArticle(input: $input, condition: $condition) {
      id
      title
      thumbnail
      language
      category
      language_category
      description
      content
      resources
      created_time
      writer_id
      url_id
      published_time
      createdAt
      updatedAt
    }
  }
`;