import React, {useContext } from 'react'
import { Row, Container, Button, Form } from 'react-bootstrap'
import { useParams, Link, navigate } from '@reach/router'
import {AdminContext} from '../../context/AdminContext'




export default function WriterEditForm() {
    const admincontext = useContext(AdminContext);
    const {writerList} = admincontext;
    const { id } = useParams();
    const writer = writerList.find(i => i.id === id);

    
   const handleSubmit = async (e) => {
       const form = e.currentTarget.elements;
       const param = {
        contact : form.contact.value,
        gender : form.gender.value,
        address : form.address.value,
        writerType : form.writerType.value,
        category : form.category.value
       }
       
    }

    const onSubmit = (e) => {
        e.preventDefault();
        navigate('/admin');
    }

    return (
        <div style={{ backgroundColor: "" }}>
            <Container  >
                <h1>Edit {writer.full_name}'s details</h1>
                <Row style={{ marginTop: "50px" }}>
                    <Form style={{ alignItems: "center" }} onSubmit={onSubmit}>
                        <Form.Group controlId="formBasicContact">
                            <Form.Label>Contact Number</Form.Label>
                            <Form.Control name="contact"  type="text" placeholder="Enter contact number" defaultValue={writer.phone_number} />
                        </Form.Group>
                        <Form.Group controlId="formBasicAddress">
                            <Form.Label>Address</Form.Label>
                            <Form.Control name="address"  type="text" placeholder="Address" defaultValue={writer.address} />
                        </Form.Group>
                        <Form.Group controlId="exampleForm.SelectCustom">
                        <Form.Label>Gender</Form.Label>
                        <Form.Control name="gender"  as="select" custom defaultValue={writer.gender}>
                            <option>Male</option>
                            <option>Female</option>
                            <option>Other</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.SelectCustom">
                        <Form.Label>Writer Type</Form.Label>
                        <Form.Control name="writerType"  as="select" custom defaultValue={writer.writer_type}>
                            <option>Hired</option>
                            <option>Freelancing</option>
                            <option>Contributing</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.SelectCustom">
                        <Form.Label>Category</Form.Label>
                        <Form.Control name="category"  as="select" custom defaultValue={writer.category}>
                            <option>Any</option>
                            <option>Sports</option>
                            <option>Life-hacks</option>
                        </Form.Control>
                    </Form.Group>
                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="I accept the terms and conditions" />
                        </Form.Group>
                        read<a href="#admin terms"> admin terms and conditions</a><br />
                        <Link to="/admin"> <Button variant="outline-success" style={{ margin: "10px" }} type="cencel">
                            Cancel
                         </Button>
                        </Link>
                        <Button variant="outline-success" style={{ margin: "10px" }} type="submit" onClick={handleSubmit} >
                            Submit
                         </Button>
                    </Form>
                </Row>
            </Container>
        </div>
    )

}