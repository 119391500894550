export const ARTICLCE_TYPE = {
    DRAFTED: 'drafted',
    RJECTED: 'rejected',
    ONREVIEW: 'onreview',
    READYTOPUBLISH: 'readytopublish',
    PUBLISHED: 'published',
};

export const ARTICLCE_TYPE_NAME = {
    DRAFTED: 'DRAFTED',
    RJECTED: 'RJECTED',
    ONREVIEW: 'ON REVIEW',
    READYTOPUBLISH: 'READY TO PUBLISH',
    PUBLISHED: 'PUBLISHED',
}

export const ARTICLCE_TYPE_SUB = {
    DRAFTED: 'You can edit now',
    RJECTED: 'Will delete soon. Ask why',
    ONREVIEW: 'Admin is reviewing',
    READYTOPUBLISH: 'Will publish soon',
    PUBLISHED: 'In the air',
}

export const TIME_MESSAGE = {
    BEFORE03: 'Really! midnight? of course, this is the greatest time.',
    BEFORE12: 'It is a Good Morning to Write.',
    BEFORE18: 'Good afternoon! Is it Hot?',
    BEFORE20: 'Good Evening! Try a new one',
    BEFORE23: 'Good time to Write!',
    ANY: 'Most of writers are writing this time.',
}

export const USER_ROLE = {
    WRITER : "WRITER",
    ADMIN:"ADMIN",
    ADMIN_SUPPORT:"ADMIN_SUPPORT"
}