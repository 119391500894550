import React, { Component, createContext } from 'react';
import { draftedArticleByWriter } from '../../api/queries';
import { getUser } from '../../api/queries';
import { API, graphqlOperation, Auth } from "aws-amplify";
import { ARTICLCE_TYPE } from './../../constants/constants';

export const WriterContext = createContext();

class WriterContextProvider extends Component {
    state = {
        drafted: [],
        onReview: [],
        readyToPublish: [],
        published: [],
        onRejected: [],
        reload: false,
        user: {}
    }

    constructor(props) {
        super(props);
        this.updateData = this.updateData.bind(this);
        this.notify = this.notify.bind(this);
    }

    async componentDidMount() {
        this.updateData();
    }
    
    notify() {
        this.updateData();
    }

    async updateData() {
        console.log('downloading context')
        const userInfo = await Auth.currentUserInfo()
        var writerId = userInfo.username
        let param = {
            writer_id: writerId, limit: 50
        }
        const result = await API.graphql(graphqlOperation(draftedArticleByWriter, param));
        const items = result.data.draftedArticleByWriter.items
        console.log(items);
        let drafted = items.filter((i) => {
            return (i.state === ARTICLCE_TYPE.DRAFTED)
        })
        let onReview = items.filter((i) => {
            return (i.state === ARTICLCE_TYPE.ONREVIEW)
        })
        let onRejected = items.filter((i) => {
            return (i.state === ARTICLCE_TYPE.RJECTED)
        })
        let readyToPublish = items.filter((i) => {
            return (i.state === ARTICLCE_TYPE.READYTOPUBLISH)
        })
        let onPublished = items.filter((i) => {
            return (i.state === ARTICLCE_TYPE.PUBLISHED)
        })

        //console.log(drafted);
        const userresult = await API.graphql(graphqlOperation(getUser, {id: writerId}));
        const user = userresult.data.getUser;
        
        console.log(user);
        this.setState({
            drafted,
            onReview,
            onRejected,
            onPublished,
            readyToPublish,
            user,
            reload: false,
        })
    }

    render() {
        return (
            <WriterContext.Provider value={{...this.state, notify: this.notify}}>
                {this.props.children}
            </WriterContext.Provider>
        );
    }
}

export default WriterContextProvider;