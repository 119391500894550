import React from 'react';
import { Form, Col, Button, Row, Container} from 'react-bootstrap';

class Refresh extends React.Component {
    constructor(props) {
        super(props);
    }

  
    render() {
        const { validated, navigateToHome } = this.state;
        if(navigateToHome === true) {
            // <Redirect to="/home" from="/home" noThrow/>
            return (
                <Container style={{marginTop: 90}}>
                    <Row>
                        <Col md={{ span: 6, offset: 3 }}>
                            <div style={{display: "flex", flexDirection: "column",alignItems: "center", justifyContent: "center", minHeight: '100vh'}}>
                                Your Request is processing!
                            </div>
                        </Col>
                    </Row>
                </Container>
            )
        }
    }
}

export default Refresh;