import React, { Component } from 'react';

class UnderDevelopment extends Component {
    render() {
        return (
            <div className="empty">
                <h5 style={{color: 'black'}}>LIHINI MEDIA</h5>
                <br/>
                <h6 style={{color: 'black'}}>Under Development</h6>
            </div>
        );
    }
}

export default UnderDevelopment;