import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap' ;
import './style.css'

class Loading extends Component {
    render() {
        return (
            <div className="empty">
                <h5 style={{color: 'black'}}>LIHINI MEDIA</h5>
                <br/>
                <h6 style={{color: 'black'}}>Loading</h6>
                <Spinner animation="grow" variant="warning" />
            </div>
        );
    }
}

export default Loading;