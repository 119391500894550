import React, { useContext } from 'react';
import { Container, Table, Button, Row, Col, Image } from 'react-bootstrap';
import { useParams } from '@reach/router';
import {AdminContext} from './../context/AdminContext';

export const WriterDetails = () => {
    const admincontext = useContext(AdminContext); 
    const {teamMemberList} = admincontext;
    const { id } = useParams();
    const writer = teamMemberList.find(i => i.id === id);
    console.log(writer);

        return (
            <div>
                <Container style={{ marginTop: "40px" }}>
                    <Row>
                        <Col xs={6} md={4}>
                            <Image src= { writer.userId.profile_picture } thumbnail />
                        </Col>
                        <Col xs={12} md={8}>
                            <h1 style={{ marginLeft: "20px", marginTop: "40px" }} >{writer.userId.user_name}</h1>
                            <b><i style={{ marginLeft: "20px"}}>{writer.email}</i></b><br/>
                            <b><i style={{ marginLeft: "20px"}}>{writer.phone_number}</i></b>
                        </Col>
                    </Row>
                </Container>
                <Container style={{ marginTop: "40px" }}>
                    <Table>
                        <tbody>
                            <tr><td>Username</td><td>{writer.userId.user_name}</td></tr>
                            <tr><td>Fullname</td><td>{writer.full_name}</td></tr>
                            <tr><td>Gender</td><td>{writer.gender}</td></tr>
                            <tr><td>Address</td><td>{writer.address}</td></tr>
                            <tr><td>Contact_Number</td><td>{writer.phone_number}</td></tr>
                            <tr><td>Email</td><td>{writer.email}</td></tr>
                            <tr><td>NIC</td><td>{writer.nic}</td></tr>
                            <tr><td>Article_Category</td><td>{writer.category}</td></tr>
                            <tr><td>Preffered_Language</td><td>{writer.language}</td></tr>
                            <tr><td>Bank Name</td><td>{writer.bank_name}</td></tr>
                            <tr><td>Account_No</td><td>{writer.bank_acc}</td></tr>
                            <tr><td>Join Date</td><td>{writer.createdAt}</td></tr>
                            <tr><td>Update Date</td><td>{writer.updatedAt}</td></tr>
                        </tbody>
                    </Table>
                </Container>
            </div>
        )
    }