import React, { Component } from 'react';
import { Link } from '@reach/router';
import { Button } from 'react-bootstrap'
import { TIME_MESSAGE } from '../../../constants/constants';

function getGreet () {
    var today = new Date()
    var curHr = today.getHours()
    if (curHr < 3) {
        return TIME_MESSAGE.BEFORE03;
    } else if (curHr < 12) {
        return TIME_MESSAGE.BEFORE12;
    } else if (curHr < 18) {
        return TIME_MESSAGE.BEFORE18;
    } else if (curHr < 20) {
        return TIME_MESSAGE.BEFORE20;
    } else if(curHr < 23) {
        return TIME_MESSAGE.BEFORE23;
    } else {
        return TIME_MESSAGE.ANY;
    }
}

class CreateArticleSection extends Component {
    render() {
        const timeGreeting = getGreet();
        return (
            <div style={{marginTop:5, marginBottom: 20}}>
                <h2>CREATE A NEW ONE</h2>
                <h5>{timeGreeting}</h5>
                <Link to="/create/article">
                    <Button variant="warning" size="lg" style={{margin: 15}} block><label style={{color: 'black'}}>Create Article</label></Button>
                </Link>
            </div>
        );
    }
}

export default CreateArticleSection;