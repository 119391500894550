import React, { Component } from 'react';
import { InputGroup, FormControl, Button} from 'react-bootstrap';

class UrlReference extends Component {
    render() {
        const { id, del, val, update } = this.props;
        return (
            <div>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text>Url Ref</InputGroup.Text>
                    </InputGroup.Prepend>
                        <FormControl onChange={(e) => {update(id,e.target.value)}} value={val} aria-label="Text Ref" />
                    <InputGroup.Append>
                        <Button onClick={()=>del(id)}>Delete</Button>
                    </InputGroup.Append>
                </InputGroup>
            </div>
        );
    }
}

export default UrlReference;