import React from 'react';
import  {Button, Form, Col, Row} from 'react-bootstrap'
import UploadImage from './../../common/UploadImage';
import { Redirect } from '@reach/router'
import { API, graphqlOperation } from "aws-amplify";
import { createDraftedArticle } from '../../../api/mutations';
import { Auth } from 'aws-amplify';
import { ARTICLE_CAT} from './../../../constants/category'
import { ARTICLCE_TYPE } from './../../../constants/constants';


class CreateArticle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            thumbnailurl: null,
            articleId: null,
        }
        this.setThumbnailUrl = this.setThumbnailUrl.bind(this);
        this.createArticle = this.createArticle.bind(this);
    }

    setThumbnailUrl(urlString) {
        console.log(urlString);
        this.setState({
            thumbnailurl: urlString
        })
    }

    async createArticle(e) {
        e.preventDefault();
        let form = e.target;
        let { thumbnailurl } = this.state;
        if(!form.elements.title.value || !form.elements.description.value || !form.elements.b_type.value) {
            return
        } else if (!form.elements.category.value || !form.elements.language.value || !thumbnailurl) {
            return
        }

        const userInfo = await Auth.currentUserInfo()
        
        var writerId = userInfo.username

        var param = {
            thumbnail: this.state.thumbnailurl,
            language: form.elements.language.value,
            category: form.elements.category.value,
            language_category: `${form.elements.language.value}#${form.elements.category.value}`,
            title: form.elements.title.value,
            description: form.elements.description.value,
            time: Date.now(),
            draftedArticleWriterId: writerId,
            writer_id: writerId,
            state: ARTICLCE_TYPE.DRAFTED,
        }
        console.log(param);
        const result = await API.graphql(graphqlOperation(createDraftedArticle, {input: param}));
        console.log(result);
        console.log(result.data.createDraftedArticle.id);
        this.setState({
            articleId: result.data.createDraftedArticle.id
        });
    }

    render() {
        console.log(this.state);
        if(this.state.articleId) return <Redirect state={{ id: this.state.articleId }} from="/" to="/create/article/content" noThrow/>;
        return (
            <div style={{ marginLeft: 8, marginRight: 8}}>
                <br/>
                <br/>
                <Row>
                    <Col md={{ span: 5, offset: 4 }}>
                        <h2>Create An Article</h2>
                        <Form onSubmit={this.createArticle}>
                            <Form.Group controlId="exampleForm.ControlInput1">
                                <Form.Label>Title</Form.Label>
                                <Form.Control name="title"  ref="title" placeholder="Enter title here" />
                            </Form.Group>
                            <br/>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>Short description</Form.Label>
                                <Form.Control as="textarea" name="description" placeholder="Enter description here" />
                            </Form.Group>
                            <br/>
                            <p> Title Image </p>
                            <UploadImage notify={this.setThumbnailUrl} />
                            <br/>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>Language</Form.Label>
                                <Form.Control name="language" as="select">
                                <option>English</option>
                                <option>සිංහල</option>
                                <option>தமிழ்</option>
                                </Form.Control>
                            </Form.Group>
                            <br/>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>Category</Form.Label>
                                <Form.Control name="category" as="select">
                                {
                                    ARTICLE_CAT.map(obj => (
                                        <option 
                                          value={obj}
                                          label={obj}
                                        />
                                      ))
                                }
                                </Form.Control>
                            </Form.Group>
                            <br/>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>B-type</Form.Label>
                                <Form.Control name="b_type" as="select">
                                    <option>Single</option>
                                    <option>List</option>
                                    <option>Sponcerd</option>
                                </Form.Control>
                            </Form.Group>
                            <br/>
                            <div>
                                Submit කිරීමට පෙර සියලුම fields fill කල යුතුය. <br/>
                                Title image එක අනිවාර්ය වේ. <br/><br/>
                            </div>
                            <Button variant="primary" type="submit">
                                Create An Article
                            </Button>
                            <div>
                                <br/>
                                <br/>
                                Submit කරාට පස්සේ එඩිට් කරන්න පුළුවන්. save වෙනවා. <br/>
                                ඔනතරමක් articles එකවර create කරන්න පුළුවන්. <br/>
                                මේක sumbit කරහම යන්නේ එඩිට් Page එකට එකේදී මේ දැන් හදන එක එඩිට් කරන්න පුළුවන්. <br/> 
                                පරන ඒවත් එඩිට් කරන්න පුළුවන්. ඔයාගේ home page එකේ under drafted එකේ පැන්සලක icon එක click කරන්න. <br/>
                                Article එකක් ගැන idea එකක් තියනවනම් මේකේ එකක් create කරන්න. පස්සේ වසිතාගෙන් අහන්න හොදයිද කියලා. (whatsapp දාන්නකෝ මේක number එක 071 144 1843) <br/>
                                (Mobile Devices වලට optimize කරා දැන් phone වලින් කරන්න පුළුවන් UI changes වල suggestions තියනවනම් msg එකක් දාන්න)
                                <br/>
                                <br/>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </div>
        );
    }
}


export default CreateArticle;