import React, { Component, createContext } from 'react';
import { listWriterRequests, listDraftedArticles, listTeamMembers } from '../../api/queries';
import { API, graphqlOperation, Auth } from "aws-amplify";

export const DevContext = createContext();

class DevContextProvider extends Component {
    state = {
        writerRequestList: [],
        draftedArticles: [],
        teamMemberList: [],
    }

    constructor(props) {
        super(props);
        this.notify = this.notify.bind(this);
        this.upadteData = this.upadteData.bind(this);
    }
    
    notify() {
        this.upadteData();
    }


    async componentDidMount() {
        this.upadteData();
    }

    async upadteData() {
        const result = await API.graphql(graphqlOperation(listWriterRequests, { limit: 50 }));
        // console.log(result.data.listWriterRequests.items);
        const draftedresult = await API.graphql(graphqlOperation(listDraftedArticles, { limit: 50 }));
        const teammemberResult = await API.graphql(graphqlOperation(listTeamMembers, { limit: 50 }))
        //console.log(teammemberResult)
        this.setState({
            writerRequestList: result.data.listWriterRequests.items,
            draftedArticles: draftedresult.data.listDraftedArticles.items,
            teamMemberList: teammemberResult.data.listTeamMembers.items
        })
    }
    
    render() {
        //console.log(this.state)
        return (
            <DevContext.Provider value={{...this.state, notify: this.notify}}>
                {this.props.children}
            </DevContext.Provider>
        );
    }
}

export default DevContextProvider;