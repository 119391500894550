import React, { Component } from 'react';
import { DevContext } from './../context/DevContext';
import { Table, Container, Tab, ListGroup,  Row, Col} from 'react-bootstrap';
import { JournalBookmarkFill, DistributeVertical } from 'react-bootstrap-icons';
import { changeGroup } from './../../api/mutations';
import { API, graphqlOperation } from 'aws-amplify'

class WriterRequests extends Component {
    static contextType = DevContext;

    constructor(props) {
        super(props);
        this.state = {
            tbnavailable: true
        }
        this.makeWriter = this.makeWriter.bind(this);
        this.setBtnavailable = this.setBtnavailable.bind(this);
    }

    setBtnavailable(b) {
        this.setState({
            tbnavailable: b
        });
    }
    

    async makeWriter(requestId, data) {
        // console.log('logic to save data');
        // console.log(requestId);
        // console.log(data);
        // console.log(JSON.parse(data.extra))
        if(this.state.tbnavailable === true) {
            this.setBtnavailable(false)
            let extra = JSON.parse(data.extra);
            let phone = '-'
            if(extra.phone_number !== undefined) {
                phone = extra.phone_number
            }
            let userData = {
                full_name: extra.name,
                address: extra.address,
                bank_name: '-',
                bank_account: '-',
                gender: extra.sex,
                phone_number: phone,
                nic: '-',
                writer_type: '-',
                user_role: 'WRITER',
                extra: '-',
                email: data.email,
                teamMemberUserIdId: data.userId.id,
                language: extra.language,
                category: extra.category,
                district: '-',
                dob: extra.dob
            }
            console.log(userData);
            var param = {
                'userId': data.userId.id,
                'groupName': 'WRITER',
                'requestId': requestId,
                'data': JSON.stringify(userData)
            }
            console.log(param)
            const result = await API.graphql(graphqlOperation(changeGroup, param));
            console.log(result);
            this.context.notify();
            this.setBtnavailable(true);
        }
    }

    render() {
        const { writerRequestList, notify } = this.context;
        return (
            <div>
                <h3 style={{fontColor:'balack'}}>WRITER REQUESTS</h3>
                <Table striped bordered hover variant="dark">
                    <thead>
                        <tr>
                        <th>Request Id</th>
                        <th>Full Name</th>
                        <th>Address</th>
                        <th>Language</th>
                        <th>Category</th>
                        <th>provided email</th>
                        <th>moblie</th>
                        <th>account email</th>
                        <th>Country</th>
                        <th>sex</th>
                        <th>delete</th>
                        <th>make a writer</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            writerRequestList.map((item) => {
                                //console.log(item)
                                var data = JSON.parse(item.extra);
                                //console.log(data)
                                return (
                                    <tr id={item.id}>
                                        <td> {item.id} </td>
                                        <td> {data.name} </td>
                                        <td> {data.address} </td>
                                        <td> {data.language} </td>
                                        <td> {data.category} </td>
                                        <td> {item.email} </td>
                                        <td> {data.phone_number} </td>
                                        <td> {item.userId.email} </td>
                                        <td> {data.country} </td>
                                        <td> {data.sex} </td>
                                        <td> <DistributeVertical onClick={() => {notify()}}/> </td>
                                        <td> <JournalBookmarkFill onClick={() =>{this.makeWriter(item.id, item)}}/> </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        );
    }
}

export default WriterRequests;