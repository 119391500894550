export const WRITER_TERMS = [
    {
        id: 1, 
        title: "Submission of Articles",
        description: "If published,your article will in principle remain published permanently, with your personal details and those of your co-authors, as well as those of the reviewers and handling editors, on the Lihini.Media website, and stored in archives, in repositories and potentially in other places. When you submit an article to Lihini.Media, you promise that:",
        content: {
            1: "The article is not under consideration by any other media website; if it has been submitted for consideration elsewhere, it has been definitively rejected and is no longer being considered for publication.If this article has been published early, can get the legal actions for the article writer.",
            2: " The article will not be submitted for publication elsewhere unless either published or rejected by Lihini.Media",
            3: "The article complies with all the rules and content",
        }
    }, 
    {
        id: 2,
        title: "Rules and Content of Articles",
        description: "All content submitted to Lihini.Media, whether or not peer-reviewed, must comply with these rules. This is your responsibility when you submit any article or content. All content must:",
        content: {
            1: "Not infringe any intellectual property rights (including copyright, moral rights and database rights) of any person.",
            2: "Be accurate or be genuinely believed to be accurate after duly rigorous investigation.",
            3: "Not be pornographic or indecent because Lihini.Media was shared knowledge with all age categories.",
            4: "Not be defamatory.",
            5: "Not be malicious, offensive, discriminatory, threatening, racist, extremist; not promote hatred or violence or denigrate any person, group of people or set of beliefs, or provide any link to any such material.",
            6: "Article has a minimum word count of 300.",
            7: "The article must be followed by Article Guide.",
        },
    },
    {
        id: 3,
        title: "Rules of Payments",
        description: "Paid articles will be published by Lihini.Media not through the author's name and unpaid articles will be published by the author's name.Both type are accepted. Some authors can write both types of articles.",
        content: {},
    },
    {
        id: 4,
        title: "Communications",
        description: "By registering with Lihini.Media as a writer, you consent to receiving email or any other communications from Lihini.Media. You must keep your email and other contact details up to date in your registration information.",
        content: {},
    },
    {
        id: 5,
        title: "Governing Law",
        description: "These terms and conditions are governed by and construed in accordance with the laws of Sri Lanka and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.",
        content: {},
    },
]

export const WRITER_TERMS_MAIN_DESCR = "These terms and conditions govern your use of this website; by using this website as a writer, you accept these terms and conditions in full. If you disagree with these terms and conditions or any part of these terms and conditions, you must not use this website as a writer."

export const WRITER_POLICY = [
    {
        id: 1,
        title: "Personal Information",
        description: "",
        content: {
            1: "Name",
            2: "NIC/Passport Number",
            3: "Date of Birth",
            4: "Email",
            5: "Social media profile(facebook,twitter)",
            6: "Phone/mobile number",
            7: "Permanent Address",
            8: "Payment Information",
        },
    },
    {
        id: 2,
        title: "Using Personal Information",
        description: "Any personal information received by Lihini.Media will only be used to: process and publish writer's articles.",
        content: {
            1: "Administer this website",
            2: "Personalize the website for you",
            3: "Process your articles",
            4: "Publish your articles",
            5: "Send to you communication about your articles",
            6: "Send payments statements",
        },
    },
    {
        id: 3,
        title: "Securing your data",
        description: "Lihini.Media will take reasonable technical and organisational precautions to prevent the loss, misuse or alteration of your personal information.",
        content: {},
    },
]

export const WRITER_POLICY_MAIN_DESCR = "Your privacy is important to Lihini.Media because you are our article writers. It is Lihini.Media policy to respect your privacy regarding any information we may collect from you across our website https://lihini.media,  we own and operate.We collect data witch you provide, When you register with Lihini.Media as a writer, you provide us with certain information, which becomes part of your registered user profile with Lihini.Media. Some basic information is required for registration, and you may choose to provide additional personal information like  link other publicly-available information about your professional activities – including articles published, awards received etc to your profile."