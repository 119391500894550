import React, { Component } from 'react';
import { Image } from 'react-bootstrap'
import { CalendarEventFill, FilterSquareFill, Globe, Box} from 'react-bootstrap-icons'

class TitleAndThumbNail extends Component {

    render() {
        const { article, titleEdit } = this.props;
        const d = new Date(article.time);
        const datetime = d.getDate() + '-' + (d.getMonth()+1) + '-' + d.getFullYear()
        return (
            <div style={{marginTop: 50}}>
                <h2>{article.title}</h2>
                <Image src={article.thumbnail} fluid/>
                <p>{article.description}</p>
                <p><Box onClick={() => {titleEdit(true)}}/> Edit Title | <Globe/> {article.language} | <FilterSquareFill/> {article.category} | <CalendarEventFill/> {datetime}</p>
            </div>
        );
    }
}

export default TitleAndThumbNail;