import React, { Component } from 'react';
import { DevContext } from './../context/DevContext';
import { Table, Button } from 'react-bootstrap';
import { JournalBookmarkFill, DistributeVertical} from 'react-bootstrap-icons';
import { deleteDraftedArticle } from './../../api/mutations';
import { API, graphqlOperation } from 'aws-amplify';

class DraftedArticles extends Component {
    static contextType = DevContext;

    constructor(props) {
        super(props);
        
    }
    
    async deleteDrafted(id) {
        const param = {
            id,
        }
        console.log(param)
        const result = await API.graphql(graphqlOperation(deleteDraftedArticle, {input: param}));
        console.log(id)
        this.context.notify()
    }

    render() {
        const { draftedArticles } = this.context;
        //console.log(draftedArticles)
        return (
            <div>
                <h3>DRAFTED ARTICLES</h3>
                <Table striped bordered hover variant="dark">
                    <thead>
                        <tr>
                        <th>Title</th>
                        <th>Language</th>
                        <th>Category</th>
                        <th>Description</th>
                        <th>Status</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                        <th>Writer Name</th>
                        <th>Corrected By</th>
                        <th>Publish</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            draftedArticles.map((item) => {
                                //console.log(item)
                                //var data = JSON.parse(item.extra);
                                //console.log(data)
                                return (
                                    <tr id={item.id}>
                                        <td> {item.title} </td>
                                        <td> {item.language} </td>
                                        <td> {item.category.replaceAll('_', ' ')} </td>
                                        <td> {item.description} </td>
                                        <td> {item.state} </td>
                                        <td> {item.createdAt.substring(0, 10)} {item.createdAt.substring(11, 19)} </td>
                                        <td> {item.updatedAt.substring(0, 10)} {item.updatedAt.substring(11, 19)} </td>
                                        <td> {item.writer.user_name} </td>
                                        <td> <Button variant="warning"><DistributeVertical onClick={() => {this.deleteDrafted(item.id)}}/></Button> </td>
                                        <td> <Button variant="warning"><JournalBookmarkFill onClick={() =>{this.makeWriter(item.id, item)}}/></Button> </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        );
    }
}

export default DraftedArticles;