export const ARTICLE_CAT = [
    "science_and_tech",
	"space_and_science",
	"computer_tech",
	"art_and_culture",
	"envirounment_and_wildlife",
	"animals",
	"apps_and_games",
	"biography",
	"movies_and_tv_series",
	"travel",
	"politics",
	"covid_19",
	"health_and_lifestyles",
	"history",
	"war",
	"business_and_startups",
	"food_and_drinks",
	"sports",
	"events",
	"for_kids",
	"writer's_eye",
	"law"
]
