import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner'
import './spinner.css';

class AuthenticatingSpinner extends Component {
    render() {
        return (
            <div className="spinnerdiv">
                <Spinner animation="border" variant="warning" />
            </div>
        );
    }
}

export default AuthenticatingSpinner;