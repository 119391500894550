import React, { Component } from 'react'
import { Col, Row, Container } from 'react-bootstrap';
import ArticleCategory from './ArticleCategory'
import { Link } from '@reach/router'


export default class index extends Component {


    render() {

        return (
            <div style={{ backgroundColor: "" }}>
                <Container >
                    <Row>
                        <Col xl={2} lg={2} md={2} sm={12} xs={12} style={{ backgroundColor: "" }}>
                            <Row style={{ marginTop: "60px" }}><Link to="/">Writers</Link></Row>
                            <Row><Link to="/articles">Articles</Link></Row>
                            <Row><Link to="/developer">Developer</Link></Row>
                            <Row><Link to="/create/article">Create Article</Link></Row>
                            <Row><Link to="/published">Published Articles</Link></Row>
                        </Col>
                        <Col xl={10} lg={10} md={10} sm={12} xs={12} >
                            <ArticleCategory />
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
