import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import { Link } from '@reach/router';
import './spinner.css'
import logo from './Logo-nbg.png';

class Unauthorized extends Component {
    render() {
        return (
            <div className="spinnerdiv">
                <Link to="/">
                    <Image class="justify-content-center" src={logo} width="300" height="250"/>
                </Link>
                <h3 style={{color:'black'}}>UNAUTHORIZED</h3>
            </div>
        );
    }
}

export default Unauthorized;