import React, {  } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import './article.css';
import ArticlePreview from './ArticlePreview'
import { AdminContext } from '../../context/AdminContext'



function AuthorDetailsforRejectedArticle(props) {
    return (
        <>
            <h3>Author: <span>{props.author}</span></h3>
            <p>email: {props.email}</p>
            <p>Reasons: {props.reasons}</p>
            <Button variant="outline-success" style={{ margin: "10px", borderRadius: "20px" }}>Send notification to author</Button>
        </>
    )
}




class ArticleReject extends React.Component {
    static contextType = AdminContext;
    constructor(props) {
        super(props);
        this.state = {
            item: null
        }

    }

    render() {
        const { rejected } = this.context;
        const item = rejected.find(i => i.id === this.props.id);
        if (item === null || item === undefined) { return (<div></div>) }
        var json = JSON.parse(item.content);
        return (
            <>
                <Row>
                    <Col md={3} xl={2} lg={2} xs={12} sm={12}></Col>
                    <Col xm={12} sm={12} md={9} lg={8} xl={8} style={{ marginLeft: "10%" }}>
                        <AuthorDetailsforRejectedArticle
                            author={item.writer.user_name}
                            email={item.writer.email}
                            reasons={item.reason} />
                    </Col>
                </Row>
                <ArticlePreview
                    title={item.title}
                    author={item.author}
                    thumbnail={item.thumbnail}
                    owner={item.owner}
                    writerImage={item.writer.profile_picture}
                    date={item.createdAt}
                    content={json.elements}
                    description={item.description} />
            </>
        );
    }
}

export default ArticleReject;