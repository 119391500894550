import React, { Component } from 'react';
import { Container, Table, Button } from 'react-bootstrap';
import { AdminContext } from './../context/AdminContext';
import { Link } from '@reach/router';

export default class WriterList extends Component {
    static contextType = AdminContext;
    render() {
        var { teamMemberList } = this.context
        if(teamMemberList === undefined) {
            teamMemberList = new Array();
        }
        return (
            <div>
                <Container>
                <h3 style={{fontColor:'black', }}> WRITER'S DETAILS </h3>
                </Container>
                <Container>
                <Table striped bordered hover variant="warning">
                    <thead>
                        <tr>
                            <th>Writer Id</th>
                            <th>Full Name</th>
                            <th>Gender</th>
                            <th>Contact Number</th>
                            <th>Email</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            teamMemberList.map((item) => {
                                console.log(item)
                                // var data = JSON.parse(item.extra);
                                // console.log(data)
                                return (
                                    <tr id={item.id}>
                                        <td> {item.id} </td>
                                        <td> {item.full_name} </td>
                                        <td> {item.gender} </td>
                                        <td> {item.phone_number} </td>
                                        <td> {item.email} </td>
                                        <td><Link to={`/admin_panel/writer/details/${item.id}`} ><Button variant="warning" > View </Button></Link></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
                </Container>
            </div>
        );
    }
}