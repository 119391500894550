import React, { Component } from 'react';
import { Link } from '@reach/router';
import { Container, Col, Row } from 'react-bootstrap';
import ArticleCard from './../../common/ArticleCard';

class Section extends Component {
    render() {
        const { type, title, sub, data } = this.props;
        
        if(Array.isArray(data) && (data.length > 0)) {
                return (
                    <div>
                        <div>
                        <h2>{title}</h2>
                        <h5>{sub}</h5>
                        <Container>
                            <Row>
                                {
                                    data.map(obj => (
                                        <Col lg={true}>
                                            <ArticleCard key={obj.id} data={obj} type={type}/>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Container>
                        <br/>
                        <Link to="/articles/type" from="/" className="float-right" style={{}}> more... </Link>
                        <br/><br/>
                        </div>
                        <hr/>
                    </div>
                );
        } else {
            return (<div></div>);
        }
    }
}

export default Section;