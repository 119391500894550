import React from 'react';
import { Image, Spinner } from 'react-bootstrap';
import 'image-upload-react/dist/index.css'
import axios from 'axios';
import { API, graphqlOperation } from "aws-amplify";
import { getImageUrl } from '../../../graphql/queries';
 
//----------------------- lihini custom image upload ----------------------
// this custom component can upload image to backend 
// if user choose image file it immediatly call backend 
// and get image upload url and then upload images to
// the back end server. the component hold the url of
// uploaded image to return to parent component using 
// notify prop create notify as a function to update
// parent component
class UploadImage extends React.Component {

  constructor(props) {
    super(props);
    this.onImageSelected = this.onImageSelected.bind(this);
    this.state = {
      file: this.props.value,
      uploading: false,
    }
    this.onImageSelected = this.onImageSelected.bind(this);
  }
  
  async onImageSelected(event) {
    const { notify, indexId } = this.props;
    notify(null, indexId);
    // read file if exist
    if(event.target.files && event.target.files[0]) {
      // throw if size > 512kb
      if(event.target.files[0].size < 1024*1024/2) {
        // throw if file is not in format
        let gqlType = 'png';
        if(event.target.files[0].type === 'image/png') {
          gqlType = 'png'
        } else if(event.target.files[0].type === 'image/jpg') {
          gqlType = 'jpg'
        } else if(event.target.files[0].type === 'image/jpeg') {
          gqlType = 'jpeg'
        } else {
          return;
        }
        this.setState({
          file: URL.createObjectURL(event.target.files[0]),
          uploading: true
        })
        
        const urlData = await API.graphql(
          graphqlOperation(
            getImageUrl, 
            {imgType: gqlType}
          )
        );
        const axiosResponse = await axios.put(urlData.data.getImageUrl.link, 
          event.target.files[0], 
          {
            headers: {
              'Content-Type': event.target.files[0].type
            }
          }
        );
        if(axiosResponse.status === 200) {
          // uploading success
          // notify to create article form
          notify(urlData.data.getImageUrl.location, indexId)
        } else {
          // uploading faild
        }
        this.setState({
          file: URL.createObjectURL(event.target.files[0]),
          uploading: false,
        })
      } else {
        this.setState({
          file: null,
          uploading: false,
        })
        // show error
      }
    } else {
      this.setState({
        file: null,
        uploading: false,
      })
      // show error
    }
  }

  render() {
    return (
      <div>
        <input type="file" onChange={this.onImageSelected}/>
        <br/>
        {(this.state.uploading) ?
          <Spinner animation="border" variant="warning" /> :
          ''
        }
        <br/>
        <Image src={this.state.file} fluid/>
      </div>
    );
  }
}

export default UploadImage;