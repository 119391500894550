import React, { Component } from 'react';
import { Table, Container, Tab, ListGroup,  Row, Col, Modal, Button} from 'react-bootstrap';
import { PenFill, VectorPen, PencilSquare, PeopleFill, HouseFill } from 'react-bootstrap-icons';
import { SwipeableDrawer,  } from '@material-ui/core';
import WriterRequests from './../../dev/WriterRequests';
import DraftedArticles from './../../dev/DraftedArticles';
import TeamMemberForm from './../../dev/TeamMemberForm';
import HomePageSettings from './../../dev/HomePageSettings';
import HomePageSettingsSinhala from './../../dev/HomePageSettingsSinhala';
import TeamMember from './../../dev/TeamMember';
import './../../dev/styles.css';
import { WriterContext } from '../../context/WriterContext';

class Developer extends Component {
    static contextType = WriterContext;
    intervalID = 0;

    constructor(props) {
        super(props);
        this.state = {
            showmodel: false,
            update: false,
        }
        this.handleClose = this.handleClose.bind(this);
        this.setShow = this.setShow.bind(this);
    }

    handleClose() {
        this.setShow(false)
    }

    setShow(b) {
        this.setState({
            showmodel: b
        })
    }

    componentDidMount() {
        //this.intervalID = setInterval(this.context.notify(), 60000);
    }

    componentWillUnmount() {
        //clearInterval(this.intervalID);
    }
    
    render() {
        const { showmodel, modaldata } = this.state;
        return (
            <div style={{ fontFamily: 'Monospace', margin: 10}}>
                {/* <SwipeableDrawer
                    open={true}
                    >

                    </SwipeableDrawer> */}
                <Modal className="modal-container custom-map-modal" show={showmodel} onHide={this.handleClose} animation={true}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add A Team Member</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <TeamMemberForm/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={this.handleClose}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Tab.Container id="list-group-tabs-example" defaultActiveKey="#writer-request">
                <Row>
                    <Col sm={1}>
                    <ListGroup>
                        <ListGroup.Item action href="#writer-request">
                            <VectorPen/>
                        </ListGroup.Item>
                        <ListGroup.Item action href="#drafted-articles">
                            <PencilSquare/>
                        </ListGroup.Item>
                        <ListGroup.Item action href="#team-members">
                            <PeopleFill/>
                        </ListGroup.Item>
                        <ListGroup.Item action href="#homepage-english">
                            <HouseFill/>
                        </ListGroup.Item>
                        <ListGroup.Item action href="#homepage-sinhala">
                            <HouseFill/>
                        </ListGroup.Item>
                    </ListGroup>
                    </Col>
                    <Col sm={9}>
                    <Tab.Content>
                        <Tab.Pane eventKey="#writer-request">
                            <WriterRequests />
                        </Tab.Pane>
                        <Tab.Pane eventKey="#drafted-articles">
                            <DraftedArticles/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="#team-members">
                            <TeamMember/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="#homepage-english">
                            <HomePageSettings/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="#homepage-sinhala">
                            <HomePageSettingsSinhala/>
                        </Tab.Pane>
                    </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
            </div>
        );
    }
}

export default Developer;