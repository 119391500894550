import React from 'react';
import { Col, Container, Row, ModalFooter, Image, Navbar, } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import footerLogo from './../../navigations/writer/footerLogo.png';


const FooterPage = () => {
  return (
          <ModalFooter className="font-small modal-footer-dark bg-dark " >
            <Container fluid className="text-center text-md-left">
              <Container>
                  <Row>
                    <Col md="3">
                      <Navbar.Brand> 
                      <h4><font className="text-center" style={{color: '#ffca18'}}>Lihini.Media</font></h4>
                      <Image class="justify-content-center" src={footerLogo} width="80" height="80"/>
                      </Navbar.Brand>
                    </Col> 
                    <Col md="3">
                      <ul>
                        <h5 className="title-center py-2"><b><font style={{color: '#ffca18'}}>About Us</font></b></h5>
                          <li className="list-unstyled" >
                            <a href="#aboutus"><font style={{color: '#ffca18'}}>About Lihini.Media</font></a>
                          </li>
                      </ul>
                    </Col>
                    <Col md="3">
                      <ul>
                        <h5 className="title-center py-2"><b><font style={{color: '#ffca18'}}>Legal</font></b></h5>
                          <li className="list-unstyled" >
                            <a href="/policy/writer"><font style={{color: '#ffca18'}}>Privacy Policy</font></a>
                          </li>
                          <li className="list-unstyled">
                            <a href="/terms/writer"><font style={{color: '#ffca18'}}>Terms and Conditions</font></a>
                          </li>
                          <li className="list-unstyled">
                            <a href="#advertisements"><font style={{color: '#ffca18'}}>Advertisements</font></a>
                          </li>
                      </ul>
                    </Col>
                    <Col md="3">
                      <ul>
                        <h5 className="title-center py-2"><b><font style={{color: '#ffca18'}}>Contact Us</font></b></h5>
                          <li className="list-unstyled" >
                            <a href="#telnumber" ><font style={{color: '#ffca18'}}>+94762971793</font></a>
                          </li>
                          <li className="list-unstyled" >
                            <a href="#email"><font style={{color: '#ffca18'}}>lihini.media@gmail.com</font></a>
                          </li>
                      </ul>
                    </Col>
                  </Row>

                  <div className="footer-copyright text-center py-2" >
                    <Container fluid className="justify-content-center" style={{color: '#ffca18'}}>
                      &copy; {new Date().getFullYear()} Copyright: <a href="https://www.team.lihini.media.com"><font style={{color: '#ffca18'}}>lihini.media</font></a>
                    </Container>
                  </div>
            </Container>
          </Container>
        </ModalFooter>
  );
}

export default FooterPage;