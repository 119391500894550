import React from 'react'
import Helmet from 'react-helmet';
import { Router } from '@reach/router';
import Home from './content/home/Home';
import Writers from './content/admin/writers';
import SignIn from './auth/SignIn'
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import SecureComponent from './auth/SecureComponent'
import Writer from './content/writer/Writer';
import WriterArticles from './content/writer/articles/WriterArticles';
import CreateArticle from './content/article/create_article/CreateArticle';
import Articles from './content/admin/articles'
import PublishedArticles from './content/admin/published/PublishedArticles'
import EditContent from './content/admin/published/EditContent'
import WriterView from './content/admin/writers/WriterView'
import WriterEditForm from './content/admin/writers/WriterEditForm'
import ArticleReadyToPublish from './content/admin/articles/ArticleReadyToPublish'
import ArticleOnReview from './content/admin/articles/ArticleOnReview'
import ArticleReject from './content/admin/articles/ArticleReject'
import ArticleRejectForm from './content/admin/articles/ArticleRejectForm'
import ArticleReadyToPublishForm from './content/admin/articles/ArticleReadyToPublishForm'
import ArticlePublishForm from './content/admin/articles/ArticlePublishForm'
import WriterNavBar from './content/navigations/writer/WriterNavBar';
import DevNavBar from './content/navigations/dev/DevNavBar';
import UserNaveBar from './content/navigations/user/UserNaveBar';
import NetworkError from './content/error/NetworkError';
import WriterTerms from './content/staticpages/WriterTerms';
import WrietrPolicy from './content/staticpages/WrietrPolicy';
import WriterProfile from './content/writer/profile/WriterProfile';
import WriterRequest from './content/writer/request/WriterRequest';
import WriterContextProvider from './content/context/WriterContext';
import ReaderTerms from './content/staticpages/ReaderTerms';
import ReaderPolicy from './content/staticpages/ReaderPolicy';
import LihiniFooter from './content/navigations/writer/LihiniFooter';
import ContentStep from './content/article/create_article/ContentStep';
import ArticleGuide from './content/staticpages/ArticleGuide';
import Dev from './content/dev/Dev';
import DevContextProvider from './content/context/DevContext'
import AdminContextProvider from './content/context/AdminContext';
import './App.css';
import WriterList from './content/admin_panel/WriterList';
import AdminNavBar from './content/navigations/admin/AdminNavBar';
import EditTitleStep from './content/article/create_article/EditTitleStep';
import Developer from './content/admin/dev/Developer'
import {WriterDetails} from './content/admin_panel/WriterDetais';
import Refresh from './content/home/Refresh';
Amplify.configure(awsconfig);
Auth.configure(awsconfig);

class Lihini extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
          isAdmin: false,
          isWriter: false,
          isDev: false,
      }
  }

  componentDidMount() {
    console.log('app mounted')
    if(this.props.msg) {
      console.log(this.props.msg)
      this.setState({
        msg: this.props.msg
      })
    }
    
    Auth.currentSession()
    .then((data) => {
        //console.log(data.getIdToken().payload["cognito:groups"]);
        if(data.getIdToken().payload["cognito:groups"][0] === "ADMIN") {
          this.setState({
            isAdmin: true,
            isWriter: false,
            isDev: false,
          })
        } else if(data.getIdToken().payload["cognito:groups"][0] === "WRITER") {
          this.setState({
            isAdmin: false,
            isWriter: true,
            isDev: false,
          })
        } else if(data.getIdToken().payload["cognito:groups"][0] === "DEV") {
          this.setState({
            isAdmin: false,
            isWriter: true,
            isDev: true,
          })
        } else {
          // fuck em
          console.log('ele')
        }
    }).catch((err) => {
        // fuck em too...
        console.log(err)
    })
  }


  render () {

    // dev module define here
    if(this.state.isDev === true) {
      return (
        <div className="wrapper">
          <DevContextProvider>
          <DevNavBar/>
          <Router>
              <SignIn path="/signin"/>
              <SignIn path="/signout"/>
              <Dev path="/"/>
              <Home default/>
          </Router>
          </DevContextProvider>
        </div>
      )
    } 

    // admin module define here
    if(this.state.isAdmin === true) {
      return (
        <div>
        <AdminContextProvider>
        <DevContextProvider>
        <AdminNavBar/>
        <Router>
            {/* <Authors path="/"/>  */}
            <SignIn path="/signin"/>
            <SignIn path="/signout"/>
            <WriterTerms path="/terms/writer"/>
            <WrietrPolicy path="/policy/writer"/>
            <ReaderTerms path="/terms/reader"/>
            <ContentStep path="/create/article/content"/>
            <ReaderPolicy path="/policy/reader"/>
            <Writers path="/" />
            <WriterView path='/admin/writer/view/:id' />
            <WriterEditForm path='/admin/writer/edit/:id' />
            <Articles path="/articles"/>
            <PublishedArticles path="/published"/>
            <EditContent path="/published/edit"/>
            <ArticleReadyToPublish path="/admin/view/ready-to-publish-article/:id" />
            <ArticleOnReview path="/admin/view/on-review-article/:id" />
            <ArticleReject path="/admin/view/rejected-article/:id"/>
            <ArticleRejectForm path="/admin/reject-article/:id"/>
            <ArticleReadyToPublishForm path="/admin/ready-to-publish-article/:id"/>
            <CreateArticle path="/create/article"/>
            <WriterList path="/admin_panel/writer/list"/>
            <ArticlePublishForm path="/admin/publish-article/:id" />
            <ContentStep path="/edit/article/content"/>
            <EditTitleStep path="edit/article/title"/>
            <WriterDetails path="/admin_panel/writer/details/:id"/>
            <Developer path="/developer"/>
            <Home default/>
        </Router>
        <LihiniFooter/>
        </DevContextProvider>
        </AdminContextProvider>
      </div>
      )
    } 

    // writer module define here
    else if(this.state.isWriter === true) {
      return (
        <div>
          <WriterContextProvider>
          <WriterNavBar/>
          <Router> 
              <Writer path="/"/>
              <SignIn path="/signin"/>
              <SignIn path="/signout"/>
              <WriterTerms path="/terms/writer"/>
              <WrietrPolicy path="/policy/writer"/>
              <ReaderTerms path="/terms/reader"/>
              <ContentStep path="/create/article/content"/>
              <ContentStep path="/edit/article/content"/>
              <EditTitleStep path="edit/article/title"/>
              <WriterArticles path="/articles/type"/>
              <ReaderPolicy path="/policy/reader"/>
              <ArticleGuide path="/article/guide/"/>
              <WriterProfile path="/profile/edit"/>
              <CreateArticle path="/create/article"/>
          </Router>
          <LihiniFooter/>
          </WriterContextProvider>
        </div>
      );
    } 
    
    // Home page here
    else {
      return (
        <div>
          <Helmet>
                <title>lihini media</title>
                <meta name="description" content="Lihini media. The unique media site for read everything for everyone. join us free" />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content="lihini media" />
                <meta itemprop="description" content="Lihini media. The unique media site for read everything for everyone. join us free" />
                <meta itemprop="image" content="https://lihinimediaarticleimages141625-prod.s3-ap-southeast-1.amazonaws.com/Logo.png" /> 

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content="https://roar.media/english/life/covid19/curfew-lockdown-restrictions-movement-on-what-legal-basis" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="lihini media" />
                <meta property="og:description" content="Lihini media. The unique media site for read everything for everyone. join us free" />
                <meta property="og:image" content="https://lihinimediaarticleimages141625-prod.s3-ap-southeast-1.amazonaws.com/Logo.png" />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="lihini media" />
                <meta name="twitter:description" content="Lihini media. The unique media site for read everything for everyone. join us free" />
                <meta name="twitter:image" content="https://lihinimediaarticleimages141625-prod.s3-ap-southeast-1.amazonaws.com/Logo.png" />

          </Helmet>
          
          <UserNaveBar/>
          <Router>
          <Home path="/home"/>
          <SignIn path="/signin"/>
          <SignIn path="/signout"/>
          <Home default/>
          <NetworkError path="/network/error"/>
          <WriterRequest path="/writer/apply"/>
          <Refresh path="/refresh"/>
          </Router>
          <LihiniFooter/>
        </div>
      );
    }
  }
}

export default Lihini;
