import React from 'react';
import { Container, ButtonGroup, Col, Row, Button} from "react-bootstrap";
import UploadContentImage from './UploadContentImage'
import ParagraphItem from './ParagraphItem';
import TitleAndThumbnail from './TitleAndThumbnail';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { API, graphqlOperation} from "aws-amplify";
import { updatePublishedArticle } from './../../../api/mutations';
import { getPublishedArticle } from './../../../api/queries';
import Loading from '../../common/Loading'
import SubtitleItem from './SubtitleItem';
import Notification from 'rc-notification';
import 'rc-notification/assets/index.css';
import { ARTICLCE_TYPE } from '../../../constants/constants';
import { Redirect } from '@reach/router'
import UrlReference from './UrlReference';
import TextReference from './TextReference';

import './style.css';
import { Trash } from 'react-bootstrap-icons';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    borderRadius: 5,

    // change background colour if dragging
    background: isDragging ? "#ffdede" : "#ffe89d",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "#ffb3b2" : "white",
    padding: grid,
    width: 430 > window.innerWidth ? (window.innerWidth - 20) : 540,
    borderRadius: 5
});
  

export default class EditContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            items: [],
            counter: 1,
            article: null,
            goTotitleEdit: false,
            resources: [],
        };
        this.addParagraph = this.addParagraph.bind(this);
        this.addImage = this.addImage.bind(this);
        this.addSubtitle = this.addSubtitle.bind(this);
        this.setUploadUrl = this.setUploadUrl.bind(this);
        this.updateResources = this.updateResources.bind(this);
        this.handleParagraphInputChange = this.handleParagraphInputChange.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.saveEdited = this.saveEdited.bind(this);
        this.setGoTotitleEdit = this.setGoTotitleEdit.bind(this);
        this.handleImageCaptionChange = this.handleImageCaptionChange.bind(this);
        this.addResource = this.addResource.bind(this);
        this.deleteResourceItem = this.deleteResourceItem.bind(this);
        this.updateResources = this.updateResources.bind(this);
    }

    addParagraph() {
        console.log('adding para')
        var item = {
            id: this.state.counter.toString(),
            type: "para",
            content: "",
            oder: 1
        }
        this.setState(({items, counter}) => ({
            items: [...items, item],
            counter: counter + 1,
        }));
    }

    addImage() {
        console.log('adding image')
        var item = {
            id: this.state.counter.toString(),
            type: "img",
            content: "",
            caption: ""
        }
        this.setState(({items, counter}) => ({
            items: [...items, item],
            counter: counter + 1,
        }));
    }

    addSubtitle() {
        console.log('adding image')
        var item = {
            id: this.state.counter.toString(),
            type: "subtitle",
            content: "",
        }
        this.setState(({items, counter}) => ({
            items: [...items, item],
            counter: counter + 1,
        }));
    }

    updateResources(id, text) {
        var resources = this.state.resources;
        resources[id].content = text; 
        this.setState(
            resources
        );
    }

    addResource(type) {
        var r = this.state.resources
        r.push({content: '', type});
        this.setState({
            resources: r
        })
    }

    deleteResourceItem(id) {
        console.log('deleting item', id)
        var resources = this.state.resources
        resources.splice(id, 1);
        this.setState(
            resources
        );
    }

    handleSubtitleInputchange(content, id) {
        var items = this.state.items;
        items.forEach((item, index) => {
            if(item.id === id) {
                items[index].content = content;
            }
        });
        this.setState(
            items
        );
    }

    handleImageCaptionChange(data, id, itemName) {
        var items = this.state.items;
        items.forEach((item, index) => {
            if(item.id === id) {
                if(itemName === "websiteurl") {
                    items[index].website = data;
                } else if(itemName === "author") {
                    items[index].author = data;
                } else if(itemName === "licenseurl") {
                    items[index].licenseurl = data;
                } else if(itemName === "licensename") {
                    items[index].licensename = data;
                } else if(itemName === "caption") {
                    items[index].caption = data;
                }
            }
        });

        this.setState(
            items
        );
    }

    handleParagraphInputChange(content, id) {
        var items = this.state.items;
        items.forEach((item, index) => {
            if(item.id === id) {
                items[index].content = content;
            }
        });

        this.setState(
            items
        );
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
    
        const items = reorder(
          this.state.items,
          result.source.index,
          result.destination.index
        );
    
        this.setState({
          items
        });
    }

    setUploadUrl(url, id) {
        console.log(url, id, this.state);
        var items = this.state.items;
        items.forEach((item, index) => {
            if(item.id === id) {
                items[index].content = url;
            }
        });

        this.setState(
            items
        );
    }

    deleteItem(id) {
        console.log('deleting item', id)
        var items = this.state.items;
        items.forEach((item, index) => {
            if(item.id === id) {
                items.splice(index, 1);
            }
        });
        this.setState(
            items
        );
    }

    async saveEdited() {
        let { id } = this.props.location.state;
        let param = {
            id,
            content: JSON.stringify({elements: this.state.items}),
            resources: JSON.stringify({elements: this.state.resources})
        }
        const result = await API.graphql({query: updatePublishedArticle, variables: {input: param}});
        Notification.newInstance({}, notification => {
            notification.notice({
                content: 'Saved to cloud',
                style: {background: '#ffca18', top: '150',right: '10%'}
            });
        });
    }

    async componentDidMount() {
        console.log(this.props);
        //console.log('above is porp after mount');
        let { id } = this.props.location.state;
        let param = {
            id,
        }
        const result = await API.graphql(graphqlOperation(getPublishedArticle, param));
        console.log('result reached');
        console.log(result.data.getPublishedArticle);
        let elements = new Array();
        //console.log(elements)
        if(result.data.getPublishedArticle.content) {
            //console.log(result.data.getDraftedArticle.content)
            elements = JSON.parse(result.data.getPublishedArticle.content)["elements"]
        }
        var resources = new Array();
        if(result.data.getPublishedArticle.resources) {
            //console.log(result.data.getDraftedArticle.references)
            console.log(result.data.getPublishedArticle.resources)
            resources = JSON.parse(result.data.getPublishedArticle.resources)["elements"]
            console.log(resources);
        }
        if(elements.length > 0) {
            this.setState({
                article: result.data.getPublishedArticle,
                items: elements,
                counter: elements.length + 1,
                resources: resources, 
            })
        } else {
            this.setState({
                article: result.data.getPublishedArticle,
                items: []
            })
        }
        
    }

    // async addToProcess() {
    //     try {
    //         let { id } = this.props.location.state;
    //         let param = {
    //             id,
    //             content: JSON.stringify({elements: this.state.items}),
    //             state : ARTICLCE_TYPE.ONREVIEW,
    //         }
    //         const result = await API.graphql({query: updateDraftedArticle, variables: {input: param}});
    //         Notification.newInstance({}, notification => {
    //             notification.notice({
    //                 content: 'The post on process now',
    //                 style: {background: '#ffca18', top: '150',right: '10%'}
    //             });
    //         });
        
    //     } catch(e) {
    //         Notification.newInstance({}, notification => {
    //             notification.notice({
    //                 content: 'Network Error',
    //                 style: {background: '#FE2E2E', top: '150',right: '10%'}
    //             });
    //         });
    //     }
    // }

    setGoTotitleEdit(b) {
        this.setState({
            goTotitleEdit: b
        })
    }

    render() {
        const { items, article, counter, resources } = this.state;
        if(!article) {return (<Loading/>)}
        if(this.state.goTotitleEdit) return <Redirect state={{ id: article.id }} from="/edit/article/content" to="/edit/article/title" noThrow/>;
        return (
            <div>
                {/* <div className="topbtn" >
                    <Col>
                    <Button variant="outline-warning" onClick={this.addImage}>+Image</Button>
                    <br/>
                    <br/>
                    <Button variant="outline-warning" onClick={this.addSubtitle}>+Title</Button>
                    <br/>
                    <br/>
                    <Button variant="outline-warning" onClick={this.addParagraph}>+Paragraph</Button>
                    <br/>
                    <br/>
                    <Button variant="outline-warning" onClick={this.saveEdited}>Save</Button>
                    </Col>
                </div> */}
                <Container>
                    <Row >
                        <Col md={{ span: 6, offset: 3 }}>
                            <TitleAndThumbnail titleEdit={this.setGoTotitleEdit} article={article}/>
                        </Col>
                        <Col md={{ span: 6, offset: 3 }}>
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                    >
                                    {this.state.items.map((item, index) => (
                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                {(item.type === 'para')?
                                                    <ParagraphItem value={item.content} indexId={item.id} handleParagraphInputChange={this.handleParagraphInputChange}/> : 
                                                    (item.type === 'img') ?
                                                        <UploadContentImage value={item.content} data={item} captionChange={this.handleImageCaptionChange} indexId={item.id} notify={this.setUploadUrl}/> : 
                                                            (item.type === 'subtitle') ?
                                                                <SubtitleItem value={item.content} indexId={item.id} handleSubtitleInputchange={this.handleParagraphInputChange}/> : ''

                                                }
                                                <Trash onClick={() =>{this.deleteItem(item.id)}}/>
                                            </div>
                                        )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                            </DragDropContext>
                            <ButtonGroup aria-label="Basic example">
                                <Button variant="outline-warning" onClick={this.addImage}>+ Image</Button>
                                <Button variant="outline-warning" onClick={this.addSubtitle}>+Title</Button>
                                <Button variant="outline-warning" onClick={this.addParagraph}>+Paragraph</Button>
                                <Button variant="outline-warning" onClick={this.saveEdited}>Save</Button>
                            </ButtonGroup>
                            <br/>
                            <p>මෙතන දාන්න මේ article එක ලියන්න තොරතුරු හොයාගත්ත තැන, ex:- websites, books, newspapers</p>
                            <h5> References/Resources </h5>
                            <ButtonGroup aria-label="Basic example">
                                <Button variant="outline-warning" onClick={()=>{this.addResource('url')}}>+ Reference URL</Button>
                                <Button variant="outline-warning" onClick={()=>{this.addResource('text')}}>+ Text as Reference</Button>
                            </ButtonGroup>
                            <br/>
                            <br/>
                            <Container fluid style={{background: '#ffe89d'}}>
                            {    
                                (resources) ? (
                                    resources.map((item, index) => {
                                        return (
                                            <div>
                                                {(item.type === "url") ? 
                                                    <UrlReference del={this.deleteResourceItem} update={this.updateResources} val={item.content} id={index}/>
                                                        : (item.type === "text") ? <TextReference del={this.deleteResourceItem} update={this.updateResources} val={item.content} id={index}/> : ''
                                                }
                                            </div>
                                        )
                                   })
                                ) : ''
                            }
                            </Container>
                            <br/>
                            <br/>
                            <Row>
                                <Col>
                                <Button onClick={this.saveEdited} > Add To Publish </Button>
                                </Col>
                            </Row>
                            <br/>
                            <br/>
                            <br/>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}