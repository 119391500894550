import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { WRITER_TERMS, WRITER_TERMS_MAIN_DESCR } from './../../constants/writer';
import './style.css';

export default class WriterTerms extends Component {
    render() {
        console.log(WRITER_TERMS)
        return (
            <div className="backgroud">
                <Row>
                    <Col  md={{ span: 6, offset: 3 }}>
                        <br/>
                        <h1>
                            Writer Terms and Conditions
                        </h1>
                        <p>
                            {WRITER_TERMS_MAIN_DESCR}
                        </p>
                        <br/>
                        {
                            WRITER_TERMS.map((element) => (
                                <Para element={element}/>
                            ))
                        }
                    </Col>
                </Row>
            </div>
        )
    }
}


const Para = ({element}) => {
    console.log(element);
    return (
        <div>
            <h3> {element.title}</h3>
            <p style={{color: 'black', marginLeft: 5}}> {element.description}</p>
            <p style={{marginLeft: 30}}> {Object.keys(element.content).map((key, value) => {
                return (<p>* {element.content[key]} </p>)
            })}</p>
        </div>
    )
}
