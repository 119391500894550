import React, { Component } from 'react';

class ParagraphItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: ""
        }
        this.onChange = this.onChange.bind(this);
    }
    
    onChange (e) {
        console.log(e.target.value)
        this.props.handleParagraphInputChange(e.target.value, this.props.indexId)
    }
    
    render() {
        const { value } = this.props;
        return (
            <div>
                <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="5"
                    value={value}
                    onChange={(e)=>{this.onChange(e)}}
                />
            </div>
        );
    }
}

export default ParagraphItem;