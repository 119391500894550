import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { draftedArticleByWriter } from './../../../graphql/queries';
import { API, graphqlOperation, Auth } from "aws-amplify";
import UnderDevelopment from './../../common/UnderDevelopment';

class WriterArticles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    async componentDidMount() {
        const userInfo = await Auth.currentUserInfo()
        
        var writerId = userInfo.username;

        let param = {
            writer_id: writerId, limit: 10
        }
        const result = await API.graphql(graphqlOperation(draftedArticleByWriter, param));
        const items = result.data.draftedArticleByWriter.items;
        //console.log(items);
        this.setState({
            items,
        })
    }

    render() {
        //const { items } = this.state;
        //console.log(items);
        return (
            <Container>
                {/* <h2>{ARTICLCE_TYPE.DRAFTED.toUpperCase()}</h2>
                
                <Container>
                    <Row>
                        {
                            items.map(obj => (
                                <Col lg={true}>
                                    <ArticleCard key={obj.id} data={obj} type={ARTICLCE_TYPE.DRAFTED}/>
                                </Col>
                            ))
                        }
                    </Row>
                </Container> */}
                <UnderDevelopment/>
            </Container>
        );
    }
}

export default WriterArticles;