import React, {  } from 'react'
import { Row, Card, Container, Table } from 'react-bootstrap'
import { AdminContext } from '../../context/AdminContext'

export default class WriterView extends React.Component {
    static contextType = AdminContext;
    constructor(props) {
        super(props);
        this.state = {
            writer: null
        }
    }

    render() {
        const { writerList } = this.context;
        const writer = writerList.find(i => i.id === this.props.id);
        if (writer === null || writer === undefined) { return (<div></div>) }
        return (
            <div style={{ backgroundColor: "" }}>
                <Container >
                    <Row style={{ marginBottom: "40px" }}>
                        <Row>
                            <Card style={{ marginLeft: "10px", borderRadius: "10px" }}>
                                <Card.Link href="#img">
                                    <Card.Img variant="top" src={writer.userId.profile_picture} className="card-image" width="300" height="300" style={{ objectFit: "cover" }} />
                                </Card.Link>
                            </Card>
                            <h1 style={{ marginLeft: "20px", marginTop: "40px" }}>{writer.full_name}</h1>
                        </Row>
                        <Table style={{ padding: "10px", marginTop: "20px" }}>
                            <tbody style={{ padding: "10px" }}>
                                <tr><td>Auther Username</td><td>{writer.userId.user_name}</td></tr>
                                <tr><td>Auther name</td><td>{writer.full_name}</td></tr>
                                <tr><td>Category</td><td>{writer.category}</td></tr>
                                <tr><td>Contact Number</td><td>{writer.phone_number}</td></tr>
                                <tr><td>Writer Type</td><td>{writer.writer_type}</td></tr>
                                <tr><td>NIC</td><td>{writer.nic}</td></tr>
                                <tr><td>Gender</td><td>{writer.gender}</td></tr>
                                <tr><td>Address</td><td>{writer.address}</td></tr>
                                <tr><td>Created at</td><td>{writer.createdAt}</td></tr>
                                <tr><td>Updated at</td><td>{writer.updatedAt}</td></tr>

                            </tbody>
                        </Table>
                    </Row>
                </Container>
            </div>
        )
    }
}
