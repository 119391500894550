import React, { Component } from 'react';
import { AmplifySignIn, AmplifyAuthenticator, AmplifySignUp, AmplifySignOut } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { Redirect } from '@reach/router'
import './login.css';

class SignIn extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hasUser: true,
            changeRoute: false,
            group: 'user'
        }
        this.changeAuthState = this.changeAuthState.bind(this);
    }
    
    

    async componentDidMount() {
        await Auth.currentSession()
        .then((data) => {
            if(Array.isArray(data.getIdToken().payload["cognito:groups"])) {
                this.setState({
                    hasUser: true,
                    group: 'user'
                })
            } else {
                this.setState({
                    hasUser: true,
                    group: 'writer',
                })
            }
        }).catch((err) => {
            this.setState({
                hasUser: false
            })
        });
    }


    async changeAuthState(data, next) {
        await Auth.currentSession()
        .then((data) => {
            this.setState({
                hasUser: true,
                changeRoute: true
            })
        }).catch((err) => {
            this.setState({
                hasUser: false
            })
        });
    }

    render() {
        if(this.state.changeRoute) {
            return <Redirect state={{ msg: 'hello' }} from="/signin" to="/" noThrow/>
        }
        if(this.state.hasUser) {
            return (
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}} className="logindiv">
                    <h1 style={{color: 'black'}}>Lihini Media</h1>
                    <AmplifySignOut handleAuthStateChange={this.changeAuthState}/>
                </div>
            )
        } else {
            return (
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}} className="logindiv">
                    <h1 style={{color: 'black'}}>Lihini Media</h1>
                    <AmplifyAuthenticator handleAuthStateChange={this.changeAuthState} usernameAlias="email">
                        <AmplifySignUp
                            slot="sign-up"
                            usernameAlias="email"
                            formFields={[
                            {
                                type: "email",
                                label: "User Name",
                                placeholder: "Enter Email as User Name",
                                required: true,
                            },
                            {
                                type: "username",
                                label: "Email",
                                placeholder: "Enter Email Here",
                                required: true,
                            },
                            {
                                type: "password",
                                label: "Password",
                                placeholder: "Enter Password",
                                required: true,
                            },
                            ]} 
                        />
                        <AmplifySignIn/>
                    </AmplifyAuthenticator>
                </div>
            );
        }
    }

}

export default SignIn;