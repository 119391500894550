export const READER_TERMS = [
    {
        id: 1,
        title: "Submission of articles",
        description: "These articles will not be submitted for publication elsewhere unless either published by Lihini.Media.Any users has been published Lihini.Media's articles elsewhere then can get legal actions to the users.",
        content: {},

    },
    {
        id: 2,
        title: "Governing Law",
        description: "These terms and conditions are governed by and construed in accordance with the laws of Sri Lanka and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.",
        content: {},

    },
]

export const READER_TERMS_MAIN_DESCR = "By accessing the website at http://lihini.media, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site."

export const READER_POLICY = [
    {
        id: 1,
        title: "We collect information when you visit our website.",
        description: "",
        content: {
            1: "Personal Information: User Name, Email, Password(Protected)",
            2: "Log Data: We record information, known as log data, including browser interaction data and other information that your device’s browser automatically sends to any website you visit. This log data includes your IP address, which browser you are using and its settings, the date and time of your request, and data on how you used our website,cookie data (we explain this below) and information about your device.",
            3: "Device Information: We collect information on the type of device you are using, its operating system, browser type and version and other information to enable us to ensure that our website operates properly with your device. This may include information unique to your device. The information your device sends to us depends on its manufacturer.",
            4: "Geo-location Data: We record (via IP addresses and time stamp) from where in the world you are accessing the article, research topic and profile pages on our websites. This does not identify any individual (unless you are a registered user and logged in) and allows us to consolidate this data to understand where readership is located, and to provide impact metrics services, such as for your article or research topic.",
        },
    },
    {
        id: 2,
        title: "Using Information",
        description: "",
        content: {
            1: "Administer this website",
            2: "Personalize the website for you",
            3: "Process the best articles",
            4: "Publish the best articles",
            5: "To publish the best articles as you most preper",
        },
    },
    {
        id: 3,
        title: "Age Limitation",
        description: "",
        content: {
            1: "Lihini.Media is for professional researchers and students interested in any categories like science,Histories etc. therefore any age people can join with us.",
            2: "Under 12 years of age children can enjoy with Lihini.Media Kids.",
            3: "Where individuals aged less than 18 are involved in Lihini.Media for Young Minds, Lihini.Medias only communicate with them via parents, guardians, teachers or mentors.",

        },
    },
    {
        id: 4,
        title: "Securing your data",
        description: "",
        content: {
            1: "Lihini.Media will take reasonable technical and organisational precautions to prevent the loss, misuse or alteration of your personal information.",
        },
    },
]

export const READER_POLICY_MAIN_DESCR = "We have created our Privacy Policy to ensure that you, our users and researchers around the world, have a clear view as to what personal data we have, how we use it and under what conditions we entrust it to our contractors; and also to clarify the choices you have. This also ensures we are in line with the latest laws on data protection."