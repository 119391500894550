import React from 'react';
import { Form, Col, Button, Row, Container} from 'react-bootstrap';
import { ARTICLE_CAT } from './../../../constants/category';
import { API, graphqlOperation, Auth } from "aws-amplify";
import { createWriterRequest } from '../../../api/mutations';
import { listWriterRequestsForUser } from '../../../api/queries';

class WriterRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            validated: false,
            navigateToHome: false,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setValidated = this.setValidated.bind(this);
        this.setNaviageTohome = this.setNaviageTohome.bind(this);
    }
    
    setValidated(b) {
        this.setState({
            validated: b
        }) 
    }

    setNaviageTohome(b) {
        this.setState({
            navigateToHome: b,
        })
    }

    async componentDidMount() {
        try {
            const result = await API.graphql(graphqlOperation(listWriterRequestsForUser, { limit: 5 }));
            console.log(result.data.listWriterRequests.items);
            const data = result.data.listWriterRequests.items
            if(data.length > 0) {
                this.setNaviageTohome(true);
            }
        } catch (e) {

        }
        //
    }
  
    async handleSubmit (event) {
        event.preventDefault();
        const form = event.currentTarget.elements;
        this.setValidated(true);
        if (event.currentTarget.checkValidity() === false) {
            return
        }
        if(!form.name.value || !form.address.value || !form.email.value || !form.phone_number) {
            return
        }
        if(!form.sex.value || !form.country.value || !form.language.value || !form.category.value) {
            return
        }
        if(!form.dob.value) {
            return
        }

        const userInfo = await Auth.currentUserInfo();
        var writerId = userInfo.username;
        var extra = {
            name: form.name.value,
            sex: form.sex.value,
            address: form.address.value,
            country: form.country.value,
            language: form.language.value,
            category: form.category.value,
            phone_number: form.phone_number.value,
            dob: form.dob.value,
        }
        var param = {
            email: form.email.value,
            extra: JSON.stringify(extra),
            writerRequestUserIdId: writerId,
            owner: writerId
        }
        try{
            //console.log(param);
            await API.graphql(graphqlOperation(createWriterRequest, {input: param}));
            console.log('wade hari dan palayan api call ekak gannam - if you are seeing this, sentence written by sinhala language with english letters. use google translate');
            //console.log(result)
            this.setNaviageTohome(true);

        } catch(e) {
            console.log(e)
        }
    };
  
    render() {
        const { validated, navigateToHome } = this.state;
        if(navigateToHome === true) {
            // <Redirect to="/home" from="/home" noThrow/>
            return (
                <Container style={{marginTop: 90}}>
                    <Row>
                        <Col md={{ span: 6, offset: 3 }}>
                            <div style={{display: "flex", flexDirection: "column",alignItems: "center", justifyContent: "center", minHeight: '100vh'}}>
                                Your Request is processing!
                            </div>
                        </Col>
                    </Row>
                </Container>
            )
        }
        return (
            <Container style={{marginTop: 90}}>
                <Row>
                    <Col md={{ span: 6, offset: 3 }}>
                        <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
                            
                            <Form.Group controlId="validationCustom01">
                                <Form.Label>Full name</Form.Label>
                                <Form.Control
                                required
                                name="name"
                                type="text"
                                placeholder="First name"
                                />
                                <Form.Control.Feedback type="invalid">Please Enter name</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="validationCustom01">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control
                                required
                                name="phone_number"
                                type="text"
                                placeholder="Phone Number"
                                />
                                <Form.Control.Feedback type="invalid">Please Enter name</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="validationCustom02">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                required
                                name="email"
                                type="email"
                                placeholder="Enter email here"
                                />
                                <Form.Control.Feedback type="invalid">Please provide valid email address</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="validationCustom01">
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                required
                                name="address"
                                type="text"
                                placeholder="Address"
                                />
                                <Form.Control.Feedback type="invalid">Please enter address</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Row>
                            <Form.Group as={Col} md="6" controlId="validationCustom03">
                                <Form.Label>Gender</Form.Label>
                                <Form.Control name="sex" as="select" required>
                                    <option>Male</option>
                                    <option>Female</option>
                                    <option>Rather not Say</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                Please provide a valid city.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustom04">
                                <Form.Label>Country</Form.Label>
                                <Form.Control name="country" type="text" placeholder="country" required />
                                <Form.Control.Feedback type="invalid">
                                Please provide a valid state.
                                </Form.Control.Feedback>
                            </Form.Group>
                            </Form.Row>


                            <Form.Row>
                            <Form.Group as={Col} md="6" controlId="validationCustom03">
                                <Form.Label>Prefferd Category</Form.Label>
                                <Form.Control name="category" as="select" required>
                                    <option>any</option>
                                        {
                                            ARTICLE_CAT.map(obj => (
                                                <option 
                                                value={obj}
                                                label={obj}
                                                />
                                            ))
                                        }
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid category.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustom04">
                                <Form.Label>Prefferd Language</Form.Label>
                                <Form.Control name="language" as="select" required>
                                    <option>English</option>
                                    <option>සිංහල</option>
                                    <option>தமிழ்</option>
                                    <option>हिंदी</option>
                                    <option>नेपाली</option>
                                    <option>española</option>
                                    <option>other</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid language.
                                </Form.Control.Feedback>
                            </Form.Group>
                            </Form.Row>
                            <Form.Row>
                            <Form.Group as={Col} md="6" controlId="validationCustom03">
                                <Form.Label>Date Of Birth</Form.Label>
                                <Form.Control name="dob" type="date" required/>
                                <Form.Control.Feedback type="invalid">
                                    Please provide a date.
                                </Form.Control.Feedback>
                            </Form.Group>
                            </Form.Row>
                            <Button type="submit">Submit</Button>
                        </Form>
                    </Col>
                </Row>
        </Container>
        );
    }
}

export default WriterRequest;