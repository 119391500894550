import React, { Component } from 'react'
import { Col, Row, Container, Table, Button } from 'react-bootstrap';
import { Link } from '@reach/router'
import { AdminContext } from '../../context/AdminContext';
import ArticleCard from './ArticleCard';


export default class PublishedArticles extends Component {
    static contextType = AdminContext;

    render() {
        const { publishedArticles } = this.context;
        //console.log(publishedArticles);
        return (
            <>

                <Container style={{ backgroundColor: "", display: "table", width: "100%", height: "100%" }}>
                    <Row>
                        <Col xl={2} lg={2} md={2} sm={12} xs={12} style={{ backgroundColor: "" }}>
                            <Row style={{ marginTop: "60px" }}><Link to="/admin">Writers</Link></Row>
                            <Row><Link to="/articles">Articles</Link></Row>
                            <Row><Link to="/developer">Developer</Link></Row>
                            <Row><Link to="/create/article">Create Article</Link></Row>
                            <Row><Link to="/published">Published Articles</Link></Row>
                        </Col>
                        <Col xl={10} lg={10} md={10} sm={12} xs={12} >
                            <h3 style={{ marginTop: "20px", marginBottom: "20px" }}> Published Articles</h3>
                                <Row>
                                    {
                                        (publishedArticles) ? (
                                            publishedArticles.map((item) => {
                                                return(
                                                    <Col xl={3} lg={3} md={6} sm={12} xs={12}>
                                                        <ArticleCard
                                                            link={`/admin/view/on-review-article/${item.id}`}
                                                            author={item.author}
                                                            title={item.title}
                                                            date={item.date}
                                                            category={item.category}
                                                            thumbnail={item.thumbnail}
                                                            owner={item.owner}
                                                            writerImage={item.writer.profile_picture}
                                                            username={item.writer.user_name} 
                                                            id={item.id}/>
                                                    </Col>
                                                )
                                            })
                                        ) : ''
                                    }
                                    
                                </Row>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}
