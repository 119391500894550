import React, {useContext } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useParams, Link, navigate } from '@reach/router'
import {AdminContext} from '../../context/AdminContext'
import  { ARTICLCE_TYPE } from '../../../constants/constants'
import  {updateDraftedArticle} from '../../../api/mutations'
import { API,graphqlOperation } from 'aws-amplify'

export default function ArticleRejectForm() {
    const admincontext = useContext(AdminContext);
    const {onReview} = admincontext;
    const { id } = useParams();
    const item = onReview.find(i => i.id === id);

    function onSubmit(e) {
        // e.preventDefault();
        navigate("/articles");
    }

    const handleSubmit = async(e) => {
        const form = e.currentTarget.elements;
        const param = {
            id:id,
            state: ARTICLCE_TYPE.RJECTED,
            // descriptive_reason: form.descriptive_reason.value,
            // brief_reason: form.brief_reason.value
        }
        await API.graphql(graphqlOperation(updateDraftedArticle,{input:param}));
        console.log('This article is rejected under described reasons');

    }

    return (
        <>
            <Form onSubmit={onSubmit} style={{ backgroundColor: "#ffcf31", margin: "10%", borderColor: "grey", paddingLeft: "10%", paddingBottom: "5%", paddingRight: "10%", paddingTop: "5%", borderStyle: "double" }}>
                <h1 style={{ fontFamily: "sans" }}>Reject the  post</h1>
                <Form.Group >
                    <Form.Label>Select reason to reject this post</Form.Label>
                    <Form.Control name="brief_reason" as="select" required >
                        <option>Copyright violations</option>
                        <option>Advertising without permission</option>
                        <option>Useless</option>
                        <option>Not suitable for LIHINI.MEDIA</option>
                        <option>Other</option>
                    </Form.Control>
                </Form.Group>

                <Form.Group >
                    <Form.Label>Describe the reason</Form.Label>
                    <Form.Control name="descriptive_reason" as="textarea" rows={3} required />
                </Form.Group>
                <Form.Group controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Agree to terms and conditions" required />
                </Form.Group>
                <a href="admin terms and condition">admin terms and condition</a><br />
                <Link to="/articles">
                    <Button variant="primary" type="cancel">
                        Cancel
                        </Button>
                </Link>
                <Button variant="primary" type="submit" onClick={handleSubmit}>
                    Submit
                  </Button>
            </Form>
        </>
    )
}
