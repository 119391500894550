import React, { Component } from 'react';
import { AmplifyAuthenticator, AmplifySignUp } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import AuthenticatingSpinner from './AuthenticatingSpinner';
import Unauthorized from './Unauthorized';

class SecureComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isAuthenticated: false,
            groups: []
        }
    }

    componentDidMount() {
        Auth.currentSession()
        .then((data) => {
            const { groups } = this.props;
            const cognitogroups = data.getIdToken().payload["cognito:groups"]
            const result = groups.every(val => cognitogroups.includes(val));

            if(result) {
                this.setState({
                    isLoading: false,
                    isAuthenticated : true,
                    groups: cognitogroups,
                })
            } else {
                this.setState({
                    isLoading: false,
                    isAuthenticated : false,
                    groups: cognitogroups,
                })
            }
        }).catch((err) => {
            //console.log('cought error')
            //console.log(err)
            this.setState({
                isLoading: false,
                isAuthenticated : false,
                groups: [],
            })
        });
    }
    

    render() {
        const { isAuthenticated, isLoading } = this.state;
        const { Component } = this.props;
        return (
            <div>
                <AmplifyAuthenticator usernameAlias="email">
                    <AmplifySignUp
                        slot="sign-up"
                        usernameAlias="email"
                        formFields={[
                        {
                            type: "email",
                            label: "User Name",
                            placeholder: "Enter Email as User Name",
                            required: true,
                        },
                        {
                            type: "username",
                            label: "Email",
                            placeholder: "Enter Email Here",
                            required: true,
                        },
                        {
                            type: "password",
                            label: "Password",
                            placeholder: "Enter Password",
                            required: true,
                        },
                        ]} 
                    />
                     { (isLoading) ? <AuthenticatingSpinner/> : ((isAuthenticated) ? <Component/> : <Unauthorized/>) }
                </AmplifyAuthenticator>
            </div>
        );
    }
}

export default SecureComponent;